<template>
	<div class="header-container rounded-xl bg-gradient-to-t from-community-primary to-community-soft items-center justify-center flex py-3 px-10">
		<img class="rounded-md" :src="chosenImage" />
		<div v-if="isOwner" class="absolute bottom-2 right-1 rounded-full h-10 w-10 z-20 flex items-center justify-center cursor-pointer">
			<AppIcon src="edit" @click="uploadProfilePhoto" />
		</div>
	</div>
	<AppUploadImage ref="uploadProfilePhoto" @uploaded="data => fileUploaded(data)" scope="user"> </AppUploadImage>
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'
	import { useCommunityPublicStore } from '@/stores/public'
	import AppIcon from '../shared/AppIcon.vue'

	export default {
		name: 'SmallImage',
		data() {
			return {
				repositioning: false,
				headerImageLoaded: false,
				chosenImage: null,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			isOwner() {
				return this.communityStore.isOwner
			},
		},
		methods: {
			uploadProfilePhoto() {
				this.$refs.uploadProfilePhoto.triggerPickfile()
			},
			async fileUploaded(fileData) {
				this.chosenImage = fileData.src
				this.$refs.uploadProfilePhoto.hide()
				await this.communityStore.updateCommunityData({ image: this.chosenImage })
			},
		},
		mounted() {
			// Set the image on mount if it already exists
			if (this.communityPublicStore.banner_image) {
				this.chosenImage = this.communityPublicStore.banner_image
			}
		},
		watch: {
			'communityStore.currentCommunity': {
				immediate: true,
				deep: true,
				handler() {
					this.headerImageLoaded = true
				},
			},
			'communityPublicStore.banner_image': {
				immediate: true,
				handler(newVal) {
					if (newVal) {
						this.chosenImage = newVal
					}
				},
			},
		},
	}
</script>

<style lang="scss">
	.header-container {
		position: relative;
	}
</style>
