<template>
	<AppCard>
		<div class="comment-reply-container w-full box-border">
			<div class="flex flex-row w-full box-border gap-x-3 justify-start p-4" :class="loading || props.loading">
				<AppUserImage :name="user?.full_name" :src="user?.profile_image" :color="user?.color" size="md" :key="user?.profile_image" />
				<div class="grow editor">
					<TipTapEditor v-model:value="message" placeholder="Write something..." editor-wrapper-class="content-wrapper"></TipTapEditor>
				</div>
				<AppButton
					class="comment-send-button"
					color="primary"
					link
					size="xs"
					@click="reply"
					:loading="loading || props.loading"
					:disabled="!hasContent"
				>
					<AppIcon src="send" class="send-icon" />
				</AppButton>
			</div>
		</div>
	</AppCard>
</template>
<script setup>
	import { ref, defineModel, computed } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import constants from '@/global_helper/constants'
	import { extractMentions } from '@/components/utils/tiptapEditorHelper'

	const props = defineProps({
		messageId: {
			type: Number,
			description: 'Id of the message to respond too',
		},
		isInline: {
			type: Boolean,
			default: false,
		},
		placeholder: {
			type: String,
			default: 'Write Something...',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		shouldScroll: {
			type: Boolean,
			default: false,
		},
		channelName: {
			type: String,
			default: null,
		},
	})
	const loading = ref(false)
	const message = defineModel('')
	const emit = defineEmits(['viewAll', 'comment-replied'])
	const communityStore = useCommunityStore()
	const user = computed(() => communityStore.self.data || { full_name: 'NA' })
	const hasContent = computed(() => message.value && message.value !== constants.TIPTAP_EMPTY_MESSAGE)

	const reply = async () => {
		const content = {
			content: message.value,
			files: [],
			audio: [],
			linkPreviews: [],
			mentions: extractMentions(`${message.value}`),
			notify: constants.MESSAGE_NOTIFY.DEFAULT,
		}
		if (props.isInline) {
			emit('comment-replied', content)
			message.value = ''
		} else {
			loading.value = true
			try {
				await communityStore.sendMessage(content, null, props.messageId)
				localStorage.setItem(`draft-${communityStore.currentChannel.channel_id}`, null)

				message.value = ''
			} catch (e) {
				alert('Failed to send message')
			}
			loading.value = false
		}
		if (props.shouldScroll) {
			let commentSection = document.getElementById('comment-section')
			commentSection.scrollTop = commentSection.scrollHeight
		}
	}
</script>

<style lang="scss" scoped>
	.comment-reply-container {
		max-width: 1024px;
		@apply bg-stan-input-bg;
		.inline-reply {
			min-height: 24px;
			@apply bg-stan-input-bg -tracking-28 leading-normal font-normal resize-none break-words;

			&::placeholder,
			&::-ms-input-placeholder {
				color: var(--stan-gray-dark-color);
				@apply font-medium;
			}

			&:disabled {
				background: var(--stan-white) !important;
			}
		}

		.comment-send-button {
			@apply border-0 min-w-5;

			&:disabled {
				:deep(.send-icon) {
					path {
						fill: var(--community-primary-light-color);
					}
				}
			}

			:deep(.send-icon) {
				path {
					fill: var(--community-primary-color);
				}
			}
		}
	}

	.editor {
		@apply overflow-x-hidden overflow-y-scroll max-h-40;

		:deep(.content-wrapper) {
			.editor {
				@apply p-1 max-w-full;
			}
		}

		:deep(.tiptap) {
			p {
				&.is-editor-empty {
					&:first-child {
						&::before {
							color: #adb5bd;
							content: attr(data-placeholder);
							float: left;
							height: 0;
							pointer-events: none;
						}
					}
				}
			}
		}
	}
</style>
