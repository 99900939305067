<template>
	<div v-if="messageList" class="audio-player">
		<template v-if="isInitialized">
			<AppButton size="xs" color="primary" class="play-button" @click="cmdTogglePlayback">
				<AppIcon v-if="isPlaying" src="pause" class="audio-icons" />
				<AppIcon v-else src="play" class="audio-icons" />
			</AppButton>
			<div v-if="isPlaying" class="flex gap-2">
				<span class="text-community-primary">{{ showPlayTime }}</span>
				<span class="opacity-50">{{ fileDuration }}</span>
			</div>
			<div class="progress-bar">
				<div class="progress" :style="{ width: `${(currentTime * 100) / timeInSecs}%` }"></div>
			</div>
			<div class="d-flex flex-column file-details">
				<div class="file-name">
					<template v-if="isPlaying">
						<AppIcon src="waveform" class="audio-icons" />
					</template>
					<template v-else>{{ time }}</template>
				</div>
			</div>
		</template>
		<div v-else class="spinner-img">
			<AppIcon src="arc" class="loading-icon animate-spin" />

			<div class="spinner-border text-white" role="status"></div>
		</div>
	</div>
	<div v-else class="audio-player-card h-full w-full">
		<div v-if="uploaded" class="flex h-full w-full justify-between items-center">
			<AppButton
				size="xs"
				color="primary"
				class="action-button"
				:class="{ 'toggle-playback-disabled': !isReady }"
				:disabled="!isReady"
				@click="cmdTogglePlayback"
			>
				<AppIcon v-if="isPlaying" src="pause" class="audio-icons" />
				<AppIcon v-else src="play" class="audio-icons" />
			</AppButton>
			<div v-if="isPlaying" class="flex gap-2">
				<span class="text-community-primary">{{ showPlayTime }}</span>
				<span class="opacity-50">{{ fileDuration }}</span>
			</div>
			<div class="progress-bar">
				<div class="progress" :style="{ width: `${(currentTime * 100) / timeInSecs}%` }"></div>
			</div>
			<p>
				<template v-if="isPlaying">
					<AppIcon src="waveform" class="audio-icons" />
				</template>
				<template v-else>{{ time }}</template>
			</p>
		</div>

		<div v-else class="spinner-img">
			<AppIcon src="arc" class="loading-icon animate-spin" />
			<div class="spinner-border text-white" role="status"></div>
		</div>
	</div>
</template>

<script>
	import { ref, toRaw, toRefs, computed, watch } from 'vue'
	import { AVBars } from 'vue-audio-visual'

	import { createComponent } from '@/components/utils/components'
	import useAudio from '@/components/composables/useAudio'

	export default createComponent({
		name: 'AppAudioRecorder',
		inheritAttrs: false,
		components: { AVBars },
		props: {
			name: {
				type: String,
				default: '',
			},
			src: {
				type: String,
				default: null,
			},
			blob: {
				type: Object,
				default: null,
			},
			time: {
				type: String,
				default: null,
			},
			autoPlay: {
				type: Boolean,
				default: false,
			},
			stop: {
				type: Number,
				default: 0,
			},
			messageList: {
				type: Boolean,
				default: false,
			},
			options: {
				type: Function,
				default: null,
			},
		},
		emits: ['start-playing'],
		setup(props, { emit }) {
			const { isReady, isMuted, isPlaying, currentTime, showPlayTime, fileDuration, stopPlayback, togglePlayback } = useAudio(
				props.src,
				props.blob,
				{ autoPlay: props.autoPlay }
			)
			const name = computed(() => props.name.split('.')[0])
			const type = computed(() => props.name.split('.')[1])
			const time = computed(() => (currentTime.value === 0 && !isPlaying.value ? fileDuration.value : showPlayTime.value))
			const timeInSecs = computed(() => {
				const [mins, secs] = fileDuration.value.split(':')
				return parseInt(secs) + parseInt(mins) * 60
			})
			const isInitialized = ref(false)

			const progress = ref(props.options === null ? 100 : 'value' in props.options ? props.options.value.progress : props.options.progress)
			const uploaded = ref(props.options === null ? true : 'value' in props.options ? props.options.value.uploaded : props.options.uploaded)
			const error = ref(props.options === null ? null : 'value' in props.options ? props.options.value.error : props.options.error)

			const cmdTogglePlayback = ev => {
				emit('start-playing', !isPlaying.value)
				togglePlayback(ev)
			}

			watch(
				() => toRefs(props.options),
				val => {
					const opt = toRaw(val)
					progress.value = opt.progress.value
					uploaded.value = opt.uploaded.value
					error.value = opt.error.value
				}
			)

			watch(
				() => isReady.value,
				() => {
					if (isReady.value) {
						isInitialized.value = true
					}
				}
			)

			watch(
				() => props.stop,
				() => {
					if (isPlaying.value) {
						stopPlayback()
					}
				}
			)

			return {
				name,
				type,
				isReady,
				isMuted,
				isPlaying,
				time,
				timeInSecs,
				currentTime,
				cmdTogglePlayback,
				fileDuration,
				showPlayTime,
				progress,
				uploaded,
				error,
				src: props.src,
				isInitialized,
			}
		},
	})
</script>

<style lang="scss" scoped>
	.audio-player-card {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 0.3rem;
		border-radius: 0.5rem;
		padding: 1rem;
		height: 60px;
		width: 20rem;
		background: #f5f5f9;
		border: 0.5px solid var(--stan-gray-dark-color);

		p {
			margin: 0;
			padding-right: 0.15rem;
		}

		:deep(.audio-icons) {
			path {
				fill: var(--community-primary-color);
			}

			rect {
				fill: var(--community-primary-color);
			}
		}

		.app-button {
			padding: 4px;
			gap: 0;
			background-color: transparent;
			border: none;

			.app-icon {
				width: 24px;
				height: 24px;
				-webkit-transition: all 0.3s ease-in-out;
				-moz-transition: all 0.3s ease-in-out;
				transition: all 0.3s ease-in-out;
			}
			&:hover,
			&.disabled {
				background-color: transparent;
				border: none;
			}
		}
		.app-button.button-xs {
			width: 28px;
			height: 28px;
			padding: 4px !important;
			gap: 0 !important;
		}
		.row {
			&.recording-time,
			&.recording-name {
				font-size: 10px;
				height: 14px;
			}
		}
		.spinner-img {
			width: 30px;
			height: 30px;
			flex-shrink: 0;
			border-radius: 8px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0.5rem;
			.spinner-border {
				width: 1rem;
				height: 1rem;
			}
		}
	}
	.progress-bar {
		@apply h-1 w-1/3 bg-stan-gray-dark grow mx-2;

		.progress {
			@apply h-full bg-community-primary;
		}
	}

	.audio-player {
		flex-direction: row;
		align-items: center;
		display: flex;
		gap: 0.5rem;
		border: 0.5px solid var(--stan-gray-dark-color);
		background: var(--stan-input-bg-color);
		border-radius: 8px;
		height: 60px;
		padding: 1rem;
		width: 20rem;
		:deep(.audio-icons) {
			path {
				fill: var(--community-primary-color);
			}

			rect {
				fill: var(--community-primary-color);
			}
		}
		.app-button.button-xs {
			padding: 4px 4px;
		}
		.play-button {
			display: flex;
			height: 32px;
			width: 32px;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			background: none;
			border: 0;
			margin: 0;
			padding: 0;
			.app-icon {
				width: 24px;
				height: 24px;
			}
		}
		.file-details {
			gap: 5px;
			font-style: normal;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
		.file-name {
			@apply text-stan-text-primary;
			font-size: 14px;
			font-weight: 600;
			line-height: 1.3;
			letter-spacing: -0.28px;
			margin-right: 10px;
		}
		.file-type {
			text-transform: uppercase;
			color: var(--text-light, #808eb6);
			font-size: 10px;
			font-weight: 400;
			line-height: 14px;
			letter-spacing: -0.2px;
			width: 100px;
		}
	}
</style>
