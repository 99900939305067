<template>
	<div class="flex flex-col gap-8 px-12" :class="{ 'pb-12': files.length }">
		<div v-for="(file, idx) in files" @mouseenter="showDeleteButton[idx] = true" @mouseleave="showDeleteButton[idx] = false">
			<div v-if="digitalAssetType(file) === constants.MEDIA_TYPES.IMAGE" class="relative">
				<img v-show="!loading[idx]" :src="file.url" :alt="file.name" @load="fileLoaded(idx)" class="w-full rounded-xl" />
				<AppButton
					v-if="(showDeleteButton[idx] || isMobile) && canModifyChannels"
					size="xs"
					class="!absolute -top-4 -right-4 flex items-center justify-center !rounded-full !p-2"
					@click="removeFile(idx)"
					icon="x"
				>
				</AppButton>
				<SolidDotsSpinner v-if="loading[idx]" color="var(--community-primary-color)" :dot-size="10" />
				<p class="text-xs text-stan-text-light mt-2">{{ file.name }}</p>
			</div>
			<div v-else-if="digitalAssetType(file) === constants.MEDIA_TYPES.VIDEO" class="relative">
				<Video
					v-if="!loading[idx]"
					:videoId="file.url"
					:video-src="file.url"
					:fcp-complete="true"
					:avoidDefaultSpacing="true"
					class="media-item w-full"
					key="video"
				></Video>
				<AppButton
					v-if="(showDeleteButton[idx] || isMobile) && canModifyChannels"
					size="xs"
					class="!absolute -top-4 -right-4 flex items-center justify-center !rounded-full !p-2 z-[10]"
					@click="removeFile(idx)"
					icon="x"
				>
				</AppButton>
				<SolidDotsSpinner v-if="loading[idx]" color="var(--community-primary-color)" :dot-size="10" />
				<p class="text-xs text-stan-text-light mt-2">{{ file.name }}</p>
			</div>
			<div
				v-else-if="digitalAssetType(file) === 'file'"
				class="relative bg-stan-foreground rounded-xl w-auto aspect-auto p-2 transition ease-in-out duration-200"
				:class="{ 'border-stan-gray-strike border-2': !loading[idx] }"
			>
				<div class="flex flex-col md:flex-row justify-between items-center gap-3" v-show="!loading[idx]">
					<div class="flex items-center gap-4">
						<div class="overflow-hidden">
							<div class="attachment-icon">
								<AppIcon src="file" />
							</div>
						</div>
						<div class="file-details">
							<div class="file-name">
								{{ file.name }}
							</div>
							<div class="file-type">
								{{ digitalAssetTypeName(file.type) }}
							</div>
						</div>
					</div>
					<AppButton
						@click="downloadFile(file)"
						icon="download"
						class="mr-1 bg-community-primary rounded-xl flex gap-2 justify-center items-center"
					>
						Download
					</AppButton>
				</div>

				<AppButton
					v-if="(showDeleteButton[idx] || isMobile) && canModifyChannels"
					size="xs"
					class="!absolute -top-4 -right-4 flex items-center justify-center !rounded-full !p-2"
					@click="removeFile(idx)"
					icon="x"
				>
				</AppButton>
				<SolidDotsSpinner v-if="loading[idx]" color="var(--community-primary-color)" :dot-size="10" />
			</div>
			<div class="relative w-full mt-6" v-else-if="digitalAssetType(file) === constants.MEDIA_TYPES.AUDIO" :key="`audio-record-${index}`">
				<div class="file-uploaded">
					<AppAudioPlayer
						:name="file.name"
						:time="file.time"
						:blob="{
							metadata: {
								id: file.id ?? file.metadata?.id,
							},
							url: file.src,
						}"
						:src="file.src"
						:stop="file.stop"
						:options="file.options"
						:key="file.src"
						@start-playing="ev => onAudioStartPlaying(index, ev)"
						class="w-[90%]"
					></AppAudioPlayer>
					<!-- <div class="action-item d-none cursor-pointer" @click="onRemoveAudioRecord(index)">
						<AppIcon src="close" />
					</div> -->
					<AppButton
						v-if="(showDeleteButton[idx] || isMobile) && canModifyChannels"
						size="xs"
						class="!absolute -top-4 -right-4 flex items-center justify-center !rounded-full !p-2"
						@click="removeFile(idx)"
						icon="x"
					>
					</AppButton>
				</div>
				<p class="text-xs text-stan-text-light mt-2">{{ file.name }}</p>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, watch, computed } from 'vue'
	import SolidDotsSpinner from '../shared/SolidDotsSpinner.vue'
	import helpers from '@/global_helper/helpers'
	import constants from '@/global_helper/constants'
	import AppIcon from '../shared/AppIcon.vue'
	import AppButton from '../shared/AppButton.vue'

	const props = defineProps({
		canModifyChannels: {
			type: Boolean,
			default: false,
		},
		files: {
			type: Array,
			default: [],
		},
		audioRecords: {
			type: Array,
			default: [],
		},
	})
	const emit = defineEmits(['remove-file'])
	const loading = ref(Array(props.files.length).fill(true))
	const showDeleteButton = ref(Array(props.files.length).fill(false))
	const isMobile = computed(() => helpers.isMobile())
	const { digitalAssetType, digitalAssetTypeName } = helpers

	const fileLoaded = idx => {
		loading.value[idx] = false
	}

	const removeFile = idx => {
		emit('remove-file', idx)
	}
	const downloadFile = file => {
		const link = document.createElement('a')
		link.href = file.url
		link.download = file.name
		document.body.appendChild(link)
		link.click()
		document.body.removeChild(link)
	}

	const onAudioStartPlaying = (index, ev) => {
		if (ev) {
			for (let i = 0; i < props.audioRecords.length; i += 1) {
				if (i !== index) {
					if (!('stop' in props.audioRecords[i])) {
						props.audioRecords[i].stop = 0
					}
					props.audioRecords[i].stop += 1
				}
			}
		}
	}

	watch(
		() => props.files,
		() => {
			const fileIds = props.files.map(({ id }) => id)
			const newLoading = {}
			fileIds.forEach((l, idx) => {
				newLoading[idx] = true
			})
			fileIds.forEach(file => {
				const idx = props.files.findIndex(({ id }) => id === file)
				if (props.files[idx]?.ready) {
					newLoading[idx] = false
				}
			})
			loading.value = newLoading
		},
		{ immediate: true, deep: true }
	)
</script>

<style lang="scss" scoped>
	.file-details {
		@apply flex flex-col select-none;
		.file-name {
			@apply truncate w-36 font-semibold text-stan-text-primary;
		}
		.file-type {
			@apply text-stan-text-light font-light;
		}
	}
	.file-uploaded {
		@apply w-full;
		.audio-player-card {
			@apply w-full;
		}
	}
</style>
