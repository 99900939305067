<template>
	<div class="">
		<ul class="d-flex align-items-center gap">
			<li v-for="(item, index) in choices" :key="'item_' + index" class="d-flex align-items-center list-none">
				<input
					type="radio"
					name="cancel_reason"
					:value="item.value"
					:disabled="item.disabled"
					:id="'item_' + index"
					@change="onCheck($event)"
					v-model="value"
				/>
				<label class="para-2 text-bold" :for="'item_' + index"> {{ item.label }} </label>
			</li>
		</ul>
	</div>
</template>
<script>
	export default {
		name: 'AppRadioButton',
		props: {
			modelValue: {
				type: [String, Number],
				default: '',
			},
			choices: {
				required: true,
				type: Array,
			},
			valueIdentifier: {
				type: String,
				default: 'value',
			},
			btnDisable: Boolean,
		},
		computed: {
			value: {
				get() {
					return this.modelValue
				},
				set(val) {
					this.$emit('update:modelValue', val)
				},
			},
		},
		methods: {
			onCheck(e) {
				this.$emit('input', e)
				if (e.target.checked) {
					try {
						// this.$parent.changeBtnStatus(false)
						this.$emit('change', false)
					} catch (er) {
						console.error(err)
					}
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	ul {
		padding: 0px;
	}
	.form-group {
		list-style: none;
		padding: 1px 0px;
	}
	[type='radio']:checked,
	[type='radio']:not(:checked) {
		position: absolute;
		left: -9999px;
	}
	[type='radio']:checked + label,
	[type='radio']:not(:checked) + label {
		position: relative;
		padding-left: 28px;
		cursor: pointer;
		line-height: 20px;
		display: inline-block;
		color: var(--stan-text-primary-color);
		user-select: none;
	}
	[type='radio']:disabled {
		&:hover {
			cursor: not-allowed;
		}
		& + label {
			color: var(--stan-text-light-color);
			&:hover {
				cursor: not-allowed;
			}
		}
	}

	[type='radio']:checked + label:before,
	[type='radio']:not(:checked) + label:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 20px;
		height: 20px;
		border: 2px solid var(--stan-gray-dark-color);
		border-radius: 100%;
		background: transparent;
	}
	[type='radio']:checked + label:before {
		border: 2px solid var(--community-primary-color);
	}
	[type='radio']:checked + label:after,
	[type='radio']:not(:checked) + label:after {
		content: '';
		width: 10px;
		height: 10px;
		background: var(--community-primary-color);
		position: absolute;
		top: 5px;
		left: 5px;
		border-radius: 100%;
		-webkit-transition: all 0.2s ease;
		transition: all 0.2s ease;
	}
	[type='radio']:not(:checked) + label:after {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	[type='radio']:checked + label:after {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
		border: 2px solid var(--community-primary-color);
	}
	.gap {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 10px;
	}
</style>
