<template>
	<section>
		<div class="profile-section bg-stan-bg">
			<div class="profile-sub-section">
				<div class="profile-sub-section-left">
					<AppIcon class="color-dark" src="lock" />
				</div>
				<div class="profile-sub-section-right w-full">
					<div class="profile-sub-secion-right-heading">
						<div class="h3 text-bold h-full">Password</div>
					</div>
					<div class="profile-sub-secion-right-body w-full">
						<AppInput
							v-model="current_password"
							class="form-input"
							label="Current Password"
							:error="v$.current_password.$errors"
							type="password"
							name="current_password"
						/>
						<p class="para-3 font-bold text-red-500" v-if="generalError">{{ generalError }}</p>
						<AppInput
							v-model="password"
							class="form-input"
							label="New Password"
							:error="v$.password.$errors"
							type="password"
							name="password"
						/>
						<AppInput
							v-model="confirm_password"
							class="form-input"
							label="Confirm Password"
							:error="v$.confirm_password.$errors"
							type="password"
							name="confirm_password"
						/>
					</div>
				</div>
			</div>
			<div class="profile-sub-section">
				<div>
					<AppButton :disabled="btnDisable" @click="updateProfileSettings">Save</AppButton>
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import { useVuelidate } from '@vuelidate/core'
	import { required, minLength, helpers, and, maxLength } from '@vuelidate/validators'
	import { useAuthStore } from '@/stores/auth'

	function passwordMatchingValidator(value) {
		return value === this.password
	}

	export default {
		setup: () => ({ v$: useVuelidate() }),
		data() {
			return {
				current_password: '',
				password: '',
				confirm_password: '',
				formErrors: {},
				formSubmitting: false,
				generalError: null,
			}
		},
		computed: {
			authStore() {
				return useAuthStore()
			},
			btnDisable() {
				return this.formSubmitting || !(this.current_password && this.password && this.confirm_password)
			},
		},
		created() {
			this.getFormData()
		},
		validations() {
			return {
				current_password: {
					required: helpers.withMessage('Please enter your current password', required),
				},
				password: {
					required: helpers.withMessage('Please enter your new password', required),
					length: helpers.withMessage('Password must be 6 - 48 characters long', and(minLength(6), maxLength(48))),
				},
				confirm_password: {
					compare_passwords: helpers.withMessage('Please enter a matching password', passwordMatchingValidator),
				},
			}
		},
		methods: {
			getFormData() {
				this.current_password = ''
				this.password = ''
				this.confirm_password = ''
			},
			async updateProfileSettings() {
				const isFormCorrect = await this.v$.$validate()
				if (isFormCorrect) {
					this.generalError = null
					this.formSubmitting = true
					const payload = {
						current_password: this.current_password,
						password: this.password,
						confirm_password: this.confirm_password,
					}
					try {
						await this.authStore.updatePassword(payload)
						this.$emit('updated')
					} catch (err) {
						console.error(err)
						this.generalError = err.response?.data?.message
					}
					this.formSubmitting = false
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.profile-section {
		@apply p-5;
		display: flex;
		width: 100%;
		flex-direction: column;
		align-items: flex-start;
		gap: 45px;

		.profile-sub-section {
			display: flex;
			align-items: flex-start;
			gap: 12px;
			align-self: stretch;
			&-left {
				display: flex;
				width: 22px;
			}
			&-right {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 20px;
				flex: 1 0 0;
			}
			.profile-sub-secion-right-heading {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				gap: 8px;
			}
			.profile-sub-secion-right-body {
				.profile-photo-wrapper {
					position: relative;
					.abs-add-image {
						position: absolute;
						right: -7px;
						bottom: -8px;
						display: inline-block;
						img {
							width: 20px;
						}
					}
				}
			}
		}
	}
</style>
