<template>
	<AppModal
		ref="video-modal"
		class="video-modal"
		:title="' '"
		class-name="block-modal fade"
		:close-button="false"
		:backdrop="false"
		@cta="handleSubmit"
	>
		<div class="flex flex-col justify-start columns-sm">
			<AppInput v-model.trim="link.href" icon="link" class="form-group" placeholder="Paste a YouTube, Loom, or Wistia video link here..." />
			<div v-if="v$.link.href.$error" class="stan-text-danger">
				{{ errorText }}
			</div>
		</div>
		<div class="flex flex-col mt-3">
			<AppButton
				name="tiptap-video-modal-remove-link-button"
				class="py-2 md-flat mt-1"
				data-dismiss="modal"
				@click="handleSubmit"
				:disabled="!link.href"
				>Embed Video
			</AppButton>
			<AppButton name="tiptap-video-modal-save" class="py-2 md-flat mt-3" @click="uploadVideo" outline icon="upload">
				Upload your own
			</AppButton>
		</div>
	</AppModal>
</template>

<script>
	import { useVuelidate } from '@vuelidate/core'
	import { required, maxLength, url, helpers } from '@vuelidate/validators'
	import { autoAddHttps } from './utils'

	export default {
		setup: () => ({ v$: useVuelidate() }),
		props: {
			editor: {
				type: Object,
				required: true,
			},
			attributes: {
				type: Object,
				default: null,
			},
		},
		data() {
			return {
				link: {
					href: '',
				},
				errorText: '',
			}
		},
		validations() {
			return {
				link: {
					href: {
						maxLength: maxLength(1024),
						required,
						url,
						supportedVideoUrl: helpers.withMessage('Invalid video link', value => {
							if (!value) return false
							const loomRegex = /https:\/\/www\.loom\.com\/share\/([a-zA-Z0-9]+)/
							const youtubeRegex =
								/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|watch)(?:\/|(?:\?v=))|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
							const wistiaRegex =
								/(?:https?:\/\/)?(?:www\.)?(?:wistia\.com\/medias\/|fast\.wistia\.net\/embed\/medias\/)([a-zA-Z0-9]+)(?:[?&].*)?/
							return loomRegex.test(value) || youtubeRegex.test(value) || wistiaRegex.test(value)
						}),
					},
				},
			}
		},
		computed: {
			errorText() {
				if (this.v$.link.href.required.$invalid) {
					return 'Please enter a video link.'
				}
				if (this.v$.link.href.supportedVideoUrl?.$invalid) {
					return 'Invalid video link'
				}
				return 'Invalid URL'
			},
		},
		methods: {
			handleCancel() {
				this.$refs['video-modal'].hide()
				this.v$.$reset()
			},
			handleSubmit() {
				const url = this.link.href
				this.v$.link.$touch()
				if (this.v$.link.$error) return

				const embedHTML = this.getEmbedHTML(url)

				if (embedHTML) {
					this.editor.chain().focus().insertContent(embedHTML).run()
					this.tick += 1
					this.handleCancel()
				}
			},
			uploadVideo() {
				this.handleCancel()
				this.$emit('uploadVideo')
			},
			getEmbedHTML(url) {
				let videoId = ''
				let platform = ''

				// Check for Loom URL
				const loomRegex = /https:\/\/www\.loom\.com\/share\/([a-zA-Z0-9]+)/
				const loomMatch = url.match(loomRegex)
				if (loomMatch) {
					videoId = loomMatch[1]
					platform = 'loom'
				}

				// Check for YouTube URL
				// eslint-disable-next-line no-useless-escape
				const youtubeRegex =
					/(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|watch)(?:\/|(?:\?v=))|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/
				const youtubeMatch = url.match(youtubeRegex)
				if (youtubeMatch) {
					videoId = youtubeMatch[1]
					platform = 'youtube'
				}

				// Check for Wistia URL
				const wistiaRegex = /(?:https?:\/\/)?(?:www\.)?(?:wistia\.com\/medias\/|fast\.wistia\.net\/embed\/medias\/)([a-zA-Z0-9]+)(?:[?&].*)?/
				const wistiaMatch = url.match(wistiaRegex)
				if (wistiaMatch) {
					videoId = wistiaMatch[1]
					platform = 'wistia'
				}

				// Generate the embed HTML based on the platform
				if (videoId && platform) {
					switch (platform) {
						case 'loom':
							return `<iframe src="https://www.loom.com/embed/${videoId}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen ></iframe>`
						case 'youtube':
							return `<iframe src="https://www.youtube.com/embed/${videoId}" frameborder="0" allowfullscreen></iframe>`
						case 'wistia':
							return `<iframe src="https://fast.wistia.net/embed/iframe/${videoId}" frameborder="0" allow="autoplay fullscreen" allowfullscreen></iframe>`
						default:
							return ''
					}
				}
				return ''
			},
			assignForm() {
				this.link.text = this.attributes.text
				this.link.href = this.attributes.href
			},
			onLoad() {
				this.$refs['video-modal'].show()
			},
			appendLink() {
				this.link.href = autoAddHttps(this.link.href)
				this.v$.link.href.$touch()
			},
		},
	}
</script>

<style scoped lang="scss">
	.video-modal {
		:deep(.header) {
			@apply text-lg mb-4;
		}
	}
</style>
