<template>
	<div class="relative overflow-visible bg-stan-foreground hover:cursor-pointer gap-3;" @click="openLink(link.url)">
		<div v-if="data.length" class="flex flex-row w-full justify-between text-stan-text-primary text-sm bg-stan-foreground">
			<div class="flex flex-row gap-2 w-full items-center">
				<AppIcon v-if="canModifyChannels" src="six-dots" class="color-text-light" />

				<div class="helpful-link-text-wrapper overflow-hidden" @click="openLink(link.url)">
					<div class="helpful-link-title para-2 text-bold text-community-primary">{{ link.title }}</div>
				</div>
				<div class="buttons-wrapper">
					<AppSelectDropdown
						v-if="canModifyChannels"
						:menu="true"
						dropdown-menu-class="!min-w-max"
						:options="selectOptions"
						@value-selected="value => valueSelected(value, link, index)"
					/>
				</div>
			</div>
		</div>
		<EditLinkModal ref="editLinkModal" :link="link" :linkIndex="index" />
	</div>
</template>

<script setup>
	import { ref, computed, defineProps, inject } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import EditLinkModal from '../modals/EditLinkModal.vue'

	const editLinkModal = ref(null)

	const stanAnalytics = inject('stanAnalytics')
	const communityStore = useCommunityStore()
	const canModifyChannels = computed(() => communityStore.canModifyChannels)

	const data = computed(() => communityStore.currentCommunity?.data?.links || [])
	const props = defineProps({
		link: {
			type: Object,
			required: true,
		},
		index: {
			type: Number,
			required: true,
		},
	})

	const selectOptions = computed(() => {
		return [
			{ value: 'edit', label: 'Edit', iconBefore: 'edit' },
			{ value: 'delete', label: 'Delete', iconBefore: 'trash' },
		]
	})

	const valueSelected = (value, link, index) => {
		switch (value) {
			case 'edit':
				showEditor()
				break
			case 'delete':
				deleteLink(link, index)
				break
		}
	}

	const showEditor = () => {
		if (canModifyChannels.value) editLinkModal.value.show()
	}

	async function deleteLink(link, linkIndex) {
		const dataLinks = data.value
		dataLinks.splice(linkIndex, 1)
		await communityStore.updateCommunityData({
			links: dataLinks,
		})
	}

	async function editLink() {
		const payload = {
			title: this.title,
			image: this.image,
			url: this.url,
		}
		try {
			this.loading = true
			const links = [...(this.communityStore.currentCommunity.data.links ?? [])]
			links[this.linkIndex] = payload
			this.communityStore.updateCommunityData({
				links,
			})
			this.$notify({
				type: 'Success',
				title: `Done!`,
				text: `Link edited.`,
			})

			this.close()
		} catch (error) {
			if (error.response?.data?.message) {
				this.$notify({ type: 'error', text: error.response?.data?.message })
			}
		}
		this.loading = false
	}
	const openLink = url => {
		stanAnalytics('community-link-open', {
			props: { url: url },
		})
		window.open(url, 'blank')
	}
</script>

<style lang="scss" scoped>
	.helpful-link- {
		&wrapper {
			@apply flex flex-row items-center justify-between  overflow-hidden rounded-xl bg-stan-foreground mb-4 hover:cursor-pointer gap-3;
		}
		&image {
			@apply rounded-full w-10 h-10;
		}
		&text-wrapper {
			@apply gap-0.5 flex flex-col max-w-[50%];
		}
		&title {
			text-transform: capitalize;
			overflow-wrap: break-word;
			word-wrap: break-word;
			word-break: break-word;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		&url {
			@apply text-stan-gray-dark;
		}
		&trash-button.app-button.link {
			@apply ml-auto mr-1 p-2;
			&:hover {
				background-color: var(--stan-gray-light-color) !important;
			}
		}
	}
	.posts-list-container {
		@apply w-full;
		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
		}
	}
	.helpful-link-button {
		@apply rounded-md hover:bg-stan-foreground p-1;
	}

	.buttons-wrapper {
		@apply ml-auto flex flex-row gap-1 items-center;
	}
</style>
