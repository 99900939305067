<template>
	<div ref="emojiWrapper" class="app-emoji-wrapper">
		<AppButton
			v-if="visible"
			size="xs"
			class="emoji-icon-button"
			:class="{ 'app-emoji-reaction': emojiReaction }"
			@click="openEmoji"
			outline
			link
			color="gray"
			@keyup.esc="openEmoji"
		>
			<slot :name="messageId ? 'emoji-picker-reaction-icon_' + messageId : 'emoji-picker-icon'">
				<AppIcon style="pointer-events: none" class="color-text-dark" src="emoji" />
			</slot>
		</AppButton>
	</div>
</template>

<script>
	import { Picker } from 'emoji-picker-element'

	export default {
		name: 'EmojiPickerContainer',

		props: {
			visible: { type: Boolean, default: true },
			emojiOpened: { type: Boolean, default: false },
			emojiReaction: { type: Boolean, default: false },
			positionTop: { type: Boolean, default: false },
			positionRight: { type: Boolean, default: false },
			messageId: { type: String, default: '' },
			emojiDataSource: { type: String, default: undefined },
			reactButtonPosition: { type: Object, default: null },
		},

		emits: ['add-emoji', 'toggle-emoji'],

		data() {
			return {
				emojiPickerHeight: 320,
				emojiPickerWidth: 300,
				emojiPickerTop: 0,
				emojiPickerRight: '',
				picker: null,
				backdrop: null,
			}
		},

		watch: {
			emojiOpened(val) {
				if (val) {
					setTimeout(() => {
						this.picker = new Picker()
						if (this.emojiDataSource) this.picker.dataset.source = this.emojiDataSource
						this.picker.style.position = 'absolute'
						this.picker.style.height = `${this.emojiPickerHeight}px`
						this.picker.style.width = `${this.emojiPickerWidth}px`
						this.picker.style['z-index'] = 2000
						if (window.innerWidth < 500) {
							if (window.innerWidth + this.emojiPickerRight > this.emojiPickerWidth) {
								this.picker.style.right = '-166px'
							} else {
								this.picker.style.right = 0
							}
						}
						this.picker.style.display = this.emojiPickerTop || !this.emojiReaction ? 'initial' : 'none'
						this.picker.classList.add('light')
						this.backdrop = document.createElement('div')
						this.backdrop.className = 'modal-backdrop fade show'
						this.backdrop.style.setProperty('--bs-backdrop-opacity', 0)
						this.backdrop.addEventListener('click', ev => {
							ev.preventDefault()
							this.openEmoji()
						})
						this.$refs.emojiWrapper.append(this.backdrop)
						this.$refs.emojiWrapper.append(this.picker)

						this.picker.shadowRoot.getElementById('search').focus()

						this.addCustomStyling()

						this.picker.shadowRoot.addEventListener('click', event => event.preventDefault())
						this.picker.shadowRoot.addEventListener('emoji-click', ({ detail }) => {
							this.$emit('add-emoji', {
								unicode: detail.unicode,
								name: detail.emoji.shortcodes[0],
							})
							this.$emit('toggle-emoji', !this.emojiOpened)
						})
						this.picker.shadowRoot.addEventListener('keyup', ev => {
							if (ev.keyCode === 27) this.openEmoji()
						})

						document.addEventListener('click', this.emojiClickOutsideClose)
					}, 0)
				} else {
					this.closeEmoji()
				}
			},
		},

		methods: {
			addCustomStyling() {
				const picker = `.picker {
				border: none;
				padding:0.5rem;
                border-radius: 8px;
                box-shadow: 0px 15px 50px rgba(0, 29, 109, 0.07), inset 0px -5px 10px rgba(0, 0, 0, 0.01);
			}`

				const nav = `.nav {
				overflow-x: auto;
			}`

				const searchBox = `.search-wrapper {
				padding-right: 2px;
				padding-left: 2px;
			}`
				const searchRow = `.search-row {
				padding-left:0;
			}`

				const search = `input.search {
				height: 32px;
				font-size: 14px;
				border-radius: 0.5rem;
				border: var(--stan-gray-light-color);
				padding: 5px 10px;
				outline: none;
				background: var(--stan-gray-light-color);
				color: var(--stan-text-primary-color);
			}
            
            .skintone-button-wrapper {
                display: none !important;
            }`

				const style = document.createElement('style')
				style.textContent = picker + nav + searchBox + search + searchRow
				this.picker.shadowRoot.appendChild(style)
			},
			closeEmoji() {
				document.removeEventListener('click', this.emojiClickOutsideClose)
				if (this.picker) this.picker.remove()
				this.picker = null
				if (this.backdrop) this.backdrop.remove()
				this.backdrop = null
				this.$emit('toggle-emoji', false)
			},
			openEmoji(ev) {
				this.$emit('toggle-emoji', ev && !this.emojiOpened)
				if (!ev) return
				if (ev.clientX == undefined || ev.clientY == undefined) {
					const containerBounds = ev.target.getBoundingClientRect()
					ev.clientY = containerBounds.top + (containerBounds.bottom - containerBounds.top) / 2
					ev.clientX = containerBounds.left + (containerBounds.right - containerBounds.left) / 2
				}
				this.setEmojiPickerPosition(ev.clientY, ev.clientX, ev.view.innerWidth, ev.view.innerHeight)
			},
			setEmojiPickerPosition(clientY, clientX, innerWidth, innerHeight) {
				const mobileSize = innerWidth < 500 || innerHeight < 700
				if (mobileSize) this.emojiPickerRight = '-50px'
				if (mobileSize) {
					this.emojiPickerRight = `${innerWidth / 2 - (this.positionTop ? 200 : 150)}px`
					this.emojiPickerRight = this.reactButtonPosition.left
					this.emojiPickerTop = this.reactButtonPosition.top
					this.emojiPickerHeight = innerHeight - 200
				} else {
					const pickerTopPosition = innerHeight - clientY > this.emojiPickerHeight + 50
					const pickerBottomPosition = clientY > this.emojiPickerHeight + 50
					if (pickerTopPosition) this.emojiPickerTop = clientY + 10
					else this.emojiPickerTop = (clientY - this.emojiPickerHeight + 9) * -1
					if (pickerTopPosition) this.emojiPickerTop = clientY + 10
					else if (pickerBottomPosition) this.emojiPickerTop = clientY - this.emojiPickerHeight - 10
					else if (innerHeight > this.emojiPickerHeight) this.emojiPickerTop = (innerHeight - this.emojiPickerHeight) / 2
					else this.emojiPickerTop = 10

					const pickerRightPosition = innerWidth - clientX > this.emojiPickerWidth + 50
					const pickerLeftPosition = clientX > this.emojiPickerWidth + 50
					if (pickerRightPosition) this.emojiPickerRight = innerWidth - clientX - this.emojiPickerWidth
					else if (pickerLeftPosition) this.emojiPickerRight = innerWidth - clientX
					else if (innerWidth > this.emojiPickerWidth) this.emojiPickerRight = (innerWidth - this.emojiPickerWidth) / 2
					else this.emojiPickerRight = 10
				}
			},
			emojiClickOutsideClose(event) {
				// Check if clicked outside of emoji clicker and hide if so

				let closePicker = true
				if (this.picker) {
					const r = this.picker.getBoundingClientRect() // Position relative to screen top left is 0,0
					const clickX = event.clientX
					const clickY = event.clientY

					if (r.top < clickY && clickY < r.bottom && r.left < clickX && clickX < r.right) {
						closePicker = false
					}
				}

				if (closePicker) {
					this.closeEmoji()
					event.preventDefault()
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.app-emoji-wrapper {
		display: flex;
		position: relative;

		:hover {
			cursor: pointer;
		}

		.emoji-icon-button {
			height: auto;
		}

		.app-emoji-reaction svg {
			height: 19px;
			width: 19px;
		}

		.app-emoji-picker {
			position: absolute;
			z-index: 9999;
			bottom: 32px;
			right: 10px;
			width: 300px;
			overflow: auto;
			box-sizing: border-box;
			border-radius: 0.5rem;
			background-color: var(--stan-white);
			box-shadow: var(--stan-box-shadow);
			scrollbar-width: none;

			&::-webkit-scrollbar {
				display: none;
			}

			&.app-emoji-picker-reaction {
				position: fixed;
				top: initial;
				right: initial;
			}
		}
	}
</style>
<style>
	emoji-picker {
		height: 100%;
		width: min(100%, 300px);
		--emoji-size: 1rem;
		--emoji-padding: 0.4rem;
		--num-columns: 9;
		--border-color: var(--stan-gray-light-color);
		--button-hover-background: var(--stan-gray-strike-color);
		--button-active-background: var(--stan-gray-strike-color);
		--indicator-color: var(--stan-white);
	}
	.skintone-button-wrapper {
		display: none !important;
	}
</style>
