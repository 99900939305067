<template>
	<div id="app">
		<div v-if="globalsStore.pageLoading" class="loading-wrapper">
			<SolidDotsSpinner color="var(--stan-gray-dark-color)" :dot-size="10" />
		</div>
		<NotificationsSection />
		<RouterView />
	</div>
</template>
<script>
	import axios from 'axios'
	import { useGlobalsStore } from '@/stores/globals'
	import { useAuthStore } from '@/stores/auth'
	import { useCommunityPublicStore } from './stores/public'

	export default {
		data() {
			return {
				isAppVersionChanged: false,
			}
		},
		computed: {
			globalsStore() {
				return useGlobalsStore()
			},
			authStore() {
				return useAuthStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
		},
		mounted() {
			this.initAppVersionCheck()
			window.addEventListener('resize', this.resizeHandler)
		},
		unmounted() {
			window.removeEventListener('resize', this.resizeHandler)
		},
		methods: {
			initAppVersionCheck(frequency = 1000 * 30) {
				const timer = setInterval(() => {
					axios
						.get(`app-version.json?v=${new Date().getTime()}`, {
							baseURL: window.location.origin,
						})
						.then(response => {
							if (response.data && response.data.timestamp && this.$appVersion.timestamp !== response.data.timestamp) {
								this.isAppVersionChanged = true
								clearInterval(timer) // stop the pulling version check after detected a version change
							}
						})
						.catch(error => {
							console.error(error)
							// this.$logError(error)
						})
				}, frequency)
			},
			resizeHandler(e) {
				if (this.$commonHelper) this.globalsStore.setIsMobile(this.$commonHelper.isMobile())
			},
		},
		watch: {
			$route(newVal) {
				this.$stanAnalytics('community-page-view', {
					props: { name: newVal.name, path: newVal.path, fullPath: newVal.fullPath, query: newVal.query, params: newVal.params },
				})
				if (this.isAppVersionChanged) {
					this.$router.go()
				}
			},
			'$route.params.communitySlug': {
				immediate: true,
				handler(slug) {
					// Get your root app element
					const appEl = document.getElementById('app')
					if (!appEl) return

					// If there's a slug, use theme-dark
					if (slug) {
						const theme_mode = this.communityPublicStore.currentCommunity.theme_mode
						appEl.className = ''
						appEl.classList.add(`theme-${theme_mode}`)
					}
					// If slug is missing (or empty), use theme-light
					else {
					}
				},
			},
		},
	}
</script>

<style lang="scss" scoped>
	.loading-wrapper {
		width: 100%;
		height: 100vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}
</style>
