<template>
	<div class="backdrop d-flex">
		<router-view />
	</div>
</template>

<script>
	import { useGlobalsStore } from '@/stores/globals'
	import { useCommunityPublicStore } from '@/stores/public'

	export default {
		name: 'AuthContainer',
		async beforeRouteEnter(to, from, next) {
			const communityPublicStore = useCommunityPublicStore()
			try {
				await communityPublicStore.setCurrentCommunity(to.params.communitySlug)
			} catch (e) {
				if (e?.response?.status == 404) {
					// TODO: Do a smart redirect to home
					return next({ name: '404' })
				}
				console.error(e)
			}
			next()
		},
		created() {
			this.globalStore.setPageLoading(false)
			this.$themeHelper.addThemeColorsToDocument(this.communityPublicStore.theme)
		},
		computed: {
			globalStore() {
				return useGlobalsStore()
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.backdrop {
		height: 100vh;
		min-width: 100vw;
		overflow: auto;
	}
</style>
