<template>
	<AppModal
		ref="renameModal"
		title=" "
		action="Rename"
		:loading="loading"
		secondary-action="Cancel"
		@cta="updateName"
		@secondaryCta="close"
		@close="close"
		:modalStyles="{ 'min-width': '500px' }"
	>
		<AppInput
			v-model="title"
			placeholder="Rename Tag"
			label="Rename Category"
			@input="() => (titleError = [])"
			:error="titleError"
			:maxlength="20"
		/>
	</AppModal>
</template>

<script>
	import { useCommunityStore } from '@/stores/communities'
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'

	export default {
		props: {
			tagId: {
				type: String,
				default: '',
			},
			tagName: {
				type: String,
				default: '',
			},
		},
		data() {
			return {
				loading: false,
				title: '',
				titleError: [],
			}
		},
		mounted() {
			this.title = this.tagName
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			tags() {
				return this.communityStore.tags
			},
			tagNames() {
				return Object.keys(this.tags).map(key => this.tags[key].name.toUpperCase())
			},
			validations() {
				return {
					title: {
						required: helpers.withMessage('Title is required', required),
						unique: helpers.withMessage('Category already exists', value => !this.tagNames.includes(value.toUpperCase())),
					},
				}
			},
		},

		methods: {
			show() {
				this.$refs.renameModal.show()
			},
			close() {
				this.$emit('close')
				this.$refs.renameModal.hide()
			},
			async updateName() {
				const v$ = useVuelidate(this.validations, { title: this.title })
				v$.value.$touch()
				if (v$.value.title.$error) {
					this.titleError = v$.value.title.$errors
					return
				}
				// validation end
				this.loading = true
				this.$stanAnalytics('community-category-update', {
					props: { categoryId: this.tagId },
				})
				await this.communityStore.updateTagName(this.tagId, this.title)
				this.loading = false
				this.close()
			},
		},
		watch: {
			tagName(val) {
				this.title = val
			},
		},
	}
</script>
