import { defineStore } from 'pinia'
import axios from 'axios'
import helpers from '@/global_helper/helpers'
import { SSE_EVENT_TYPE, SSE_CHANNEL_TYPE_PREFIXES } from '@/common/constants/sseEvents'
import { useCommunityStore } from './communities/index'
import constants from '@/global_helper/constants'

function handleStreamEvents(ev, store) {
	if (helpers.hasTargetedEventType(ev.type, SSE_CHANNEL_TYPE_PREFIXES)) {
		return
	}

	const event = JSON.parse(JSON.stringify(ev.data))
	const { event_name: eventName, data: eventData } = event

	switch (eventName) {
		case SSE_EVENT_TYPE.UPDATE_RESOURCE:
		case SSE_EVENT_TYPE.ADD_RESOURCE: {
			updateResourceDataInState(eventData, store)
			break
		}
		case SSE_EVENT_TYPE.DELETE_RESOURCE: {
			deleteResourceInState(eventData, store)
			break
		}
		default: {
			break
		}
	}
}

function updateResourceDataInState(resource, store, isOrderUpdate = false) {
	const communityStore = useCommunityStore()
	const currentCommunitySlug = resource.community_id
		? `${communityStore.communityIdToSlugMap[resource.community_id]}`
		: `${communityStore._current_community_slug}`
	const currentChannelName = communityStore.channelIdToNameMap[resource.channel_id]

	if (!store._resources[currentCommunitySlug]) {
		store._resources[currentCommunitySlug] = {}
	}
	if (!store._resources[currentCommunitySlug][currentChannelName]) {
		store._resources[currentCommunitySlug][currentChannelName] = []
	}

	// Check if resource already exsits
	const currentIndex = store._resources[currentCommunitySlug][currentChannelName].findIndex(r => r.resource_id == resource.resource_id)
	if (currentIndex >= 0) {
		if (isOrderUpdate) {
			store._resources[currentCommunitySlug][currentChannelName][currentIndex]["data"].order = resource.data.order
		} 
		else 
		{
			store._resources[currentCommunitySlug][currentChannelName][currentIndex] = resource
		}
	} else {
		store._resources[currentCommunitySlug][currentChannelName].unshift(resource)
	}
}

function deleteResourceInState(resource, store) {
	const communityStore = useCommunityStore()
	const currentCommunitySlug = resource.community_id
		? `${communityStore.communityIdToSlugMap[resource.community_id]}`
		: `${communityStore._current_community_slug}`
	const currentChannelName = communityStore.channelIdToNameMap[resource.channel_id]

	if (!store._resources[currentCommunitySlug]) {
		store._resources[currentCommunitySlug] = {}
	}
	if (!store._resources[currentCommunitySlug][currentChannelName]) {
		store._resources[currentCommunitySlug][currentChannelName] = []
	}

	// find resource
	const currentIndex = store._resources[currentCommunitySlug][currentChannelName].findIndex(r => r.resource_id == resource.resource_id)

	if (currentIndex >= 0) {
		store._resources[currentCommunitySlug][currentChannelName].splice(currentIndex, 1)
	}
}

export const useResourceStore = defineStore('resourceStore', {
	state: () => ({
		_resources: {},
		_loading: false,
		_current_community_slug: null,
		_current_channel_name: null,
	}),
	getters: {
		channelResources: state => state._resources[state._current_community_slug] || {},
		resources: state => {
			const channelResources = state._resources[state._current_community_slug] || {}
			return channelResources[state._current_channel_name] || []
		},
		loading: state => state._loading,
		currentCommunitySlug: state => state._current_community_slug,
		currentCommunityChannel: state => state._current_channel_name,
	},
	actions: {
		getResources() {
			return new Promise((resolve, reject) => {
				this._loading = true
				axios
					.get(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/resources`)
					.then(response => {
						this._loading = false
						this._resources[this._current_community_slug][this._current_channel_name] = response.data.data
						this.$sse.on('message', ev => handleStreamEvents(ev, this))
						resolve(this.resources)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		async setCurrentCommunity(communitySlug) {
			this._current_community_slug = communitySlug
			if (!this._resources[this._current_community_slug]) {
				this._resources[this._current_community_slug] = {}
			}

			// Default channel. Since we only use one channel for now, avoid leaving space for channel name to not load
			this.setCurrentChannel(constants.FORUM_CHANNEL)
		},
		async setCurrentChannel(channelName) {
			this._current_channel_name = channelName
			await this.getResources()
		},
		createResource(payload) {
			// const communityStore = useCommunityStore()
			// const localresource = {
			// 	resource_id: `resource-${payload.data.title}`, // Similar trick we user for adding modules to courses in admin (temp_id basically)
			// 	creator_id:  communityStore.currentCommunity.owner_id,
			// 	channel_id: communityStore.currentChannel.channel_id,
			// 	status: 'active',
			// 	data: payload.data

			// }
			// updateResourceDataInState(localresource, this)
			return new Promise((resolve, reject) => {
				axios
					.post(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/resources`, payload)
					.then(response => {
						const newResource = response.data.resource
						updateResourceDataInState(newResource, this)
						resolve(newResource)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		updateResource(payload, resourceId) {
			return new Promise((resolve, reject) => {
				axios
					.put(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/resources/${resourceId}`, payload)
					.then(response => {
						const updateResource = response.data.resource
						updateResourceDataInState(updateResource, this)
						resolve(updateResource)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		updateResourceOrder(payload)
		{
			return new Promise((resolve, reject) => {
				axios
					.put(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/resources/order`, payload)
					.then(response => {
						response.data.updated_resources.forEach(updateResource =>{
							updateResourceDataInState(updateResource,this, true)
						})
						resolve(response.data)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		deleteResource(resourceId) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/resources/${resourceId}`)
					.then(() => {
						const currentResources = this._resources[this._current_community_slug][this._current_channel_name]
						const resrouceDelete = currentResources.find(r => r.resource_id == resourceId)
						deleteResourceInState(resrouceDelete, this)
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		deleteProductResource(productId) {
			const currentResources = this._resources[this._current_community_slug][this._current_channel_name]
			const resrouceDelete = currentResources.find(r => r.data.productId == productId)
			deleteResourceInState(resrouceDelete, this)
			return new Promise((resolve, reject) => {
				axios
					.delete(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/product_resources/${productId}`)
					.then(response => {
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		createFolderResource(firstResourceId, secondResourceId, payload){
			return new Promise((resolve, reject) => {
				axios
					.post(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/resources`, payload)
					.then(response => {
						const newResource = response.data.resource
						const currentResources = this._resources[this._current_community_slug][this._current_channel_name]
						const firstResource = currentResources.find(resource => resource.resource_id === firstResourceId )
						const secondResource = currentResources.find(resource => resource.resource_id === secondResourceId)
						if (Array.isArray(firstResource?.data?.productId) && firstResource.data.productId[0] === null) {
							delete firstResource.data.productId;
						}
						  if (Array.isArray(secondResource?.data?.productId) && secondResource.data.productId[0] === null) {
							delete secondResource.data.productId;
						}
						updateResourceDataInState(newResource, this)
						this.updateResource({
							status: firstResource.status,
							parent_resource_id: newResource.resource_id,
							data: firstResource.data

						},firstResourceId)
						this.updateResource({
							status: secondResource.status,
							parent_resource_id: newResource.resource_id,
							data: secondResource.data
						},secondResourceId)
						resolve(newResource)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		addResourceToGroup(firstResourceId, groupResourceId) {
			const currentResources = this._resources[this._current_community_slug][this._current_channel_name]
			const firstResource = currentResources.find(resource => resource.resource_id === firstResourceId)
			if (Array.isArray(firstResource?.data?.productId) && firstResource.data.productId[0] === null) {
				delete firstResource.data.productId
			}
			this.updateResource(
				{
					status: firstResource.status,
					parent_resource_id: groupResourceId,
					data: firstResource.data,
				},
				firstResourceId
			)
		},
	},
})
