<template>
	<a class="post-attachement-container" download :href="src" target="_blank">
		<div class="w-full h-full overflow-hidden">
			<div class="attachment-icon">
				<AppIcon src="file" />
			</div>
		</div>

		<div class="file-details">
			<div class="file-name">
				{{ name }}
			</div>
			<div class="file-type">
				{{ digitalAssetTypeName(type) }}
			</div>
		</div>
	</a>
</template>
<script>
	import { digitalAssetTypeName } from '@/components/shared/utils'

	export default {
		name: 'PostAttachement',
		data() {
			return {}
		},
		props: {
			type: {
				type: String,
				// validator: value => [constants.MEDIA_TYPES.AUDIO, constants.MEDIA_TYPES.FILE].includes(value),
				description: 'type of attachment',
			},
			name: {
				type: String,
				default: '',
				description: 'File name to show',
			},
			src: {
				type: String,
				description: 'Source data for attachment',
			},
		},
		methods: {
			digitalAssetTypeName,
		},
	}
</script>

<style lang="scss" scoped>
	.post-attachement-container {
		@apply h-14 relative bg-stan-foreground  border-stan-gray-strike border-2 rounded-xl w-auto aspect-auto max-w-52 flex gap-3 p-2 transition ease-in-out duration-200;
		.attachment-icon {
			@apply flex items-center rounded cursor-pointer;
			:deep(svg) {
				@apply bg-community-light w-8 h-8 aspect-square rounded p-1;
				rect,
				path {
					@apply fill-community-primary-d-text-color;
				}
			}
		}
		.file-details {
			@apply flex flex-col select-none;
			.file-name {
				@apply truncate w-36 font-semibold text-stan-text-primary;
			}
			.file-type {
				@apply text-stan-text-light font-light;
			}
		}

		&:hover {
			@apply bg-stan-gray-soft;
		}
	}
</style>
