import { Node, mergeAttributes } from '@tiptap/vue-3'

const DEFAULT_STYLE = 'width:100%;height:auto;max-width:100%;border:none;aspect-ratio:1.65;overflow:hidden;'

export default Node.create({
	name: 'VideoEmbed',
	group: 'inline',
	inline: true,
	atom: true,
	draggable: true,

	addAttributes() {
		return {
			src: {
				default: 'https://stan.store',
			},
			style: {
				default: DEFAULT_STYLE,
				parseHTML: () => null, // Ignore pasted style
			},
			scrolling: {
				default: 'no',
			},
			title: {
				default: 'Embed',
			},
			loading: {
				default: 'lazy',
			},
			width: {
				default: 100,
				parseHTML: () => null, // Ignore pasted width
			},
		}
	},
	parseHTML() {
		return [
			{
				tag: 'iframe',
			},
		]
	},

	renderHTML({ HTMLAttributes }) {
		return ['iframe', mergeAttributes(HTMLAttributes)]
	},

	addNodeView() {
		return ({ node }) => {
			const dom = document.createElement('div')
			dom.classList.add('embed-video')

			const content = document.createElement('iframe')
			content.src = node.attrs.src
			content.scrolling = node.attrs.scrolling
			content.style = DEFAULT_STYLE
			content.width = 100
			content.innerHTML = node.attrs.name
			dom.append(content)

			return {
				dom,
			}
		}
	},
})
