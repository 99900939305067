<template>
	<Teleport to="#app">
		<AppModalFrame ref="modal" extraLarge :padding="false" :close-on-blur="false" @close="hide">
			<div class="content-wrap">
				<div class="resource-item-wrapper">
					<ResourceContent @saved="hide" class="h-full" />
				</div>
			</div>
		</AppModalFrame>
	</Teleport>
</template>
<script setup>
	import { ref } from 'vue'

	const modal = ref(null)
	const show = () => {
		modal.value.show()
	}
	const hide = () => {
		modal.value.hide()
	}

	defineExpose({
		show,
	})
</script>
<style lang="scss" scoped>
	:deep(.modal.modal-xl) {
		@apply rounded-none lg:rounded-xl h-screen lg:h-4/5 max-w-none lg:max-w-80;
	}

	.content-wrap {
		@apply h-full lg:flex lg:flex-row overflow-y-scroll lg:overflow-y-visible lg:pb-0;
	}

	.resource-item-wrapper {
		@apply w-full lg:w-7/12 grow bg-stan-foreground overflow-y-scroll overflow-x-hidden flex h-full;
		.resource-container {
			@apply p-7;
		}
	}
</style>
