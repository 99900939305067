<template>
	<div
		class="shadow-stan-box-shadow relative overflow-visible rounded-xl bg-stan-foreground mb-4 p-4 px-3 gap-3;"
		@click="openWebinarPage(session.date, session.time)"
	>
		<div class="flex flex-row w-full justify-between rounded-xl text-stan-text-primary text-sm">
			<div class="flex flex-row gap-2 w-full items-center justify-between px-3">
				<div>{{ formatDate(session.date) }}</div>
				<div>
					{{ convertTimeRange(session.time, webinar?.data?.product.webinar.duration_minutes, webinar?.data?.product.webinar.timezone) }}
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import CONSTANTS from '@/global_helper/constants'
	import { configSettings } from '@/plugins/configSettings'
	import { useCommunityPublicStore } from '@/stores/public'
	import { DateTime } from 'luxon'
	import { defineProps } from 'vue'

	const props = defineProps({
		session: {
			type: Object,
			required: true,
		},
		webinar: {
			type: Object,
			required: true,
		},
	})
	const communityPublicStore = useCommunityPublicStore()
	const dateForQueryParam = (date, time) => {
		return `${date} ${time}`
	}
	const openWebinarPage = (date, time) => {
		window.open(
			`${configSettings.frontDomain}/${communityPublicStore.username}/p/${props.webinar.slug}?selected=${dateForQueryParam(date, time)}`,
			'blank'
		)
	}

	function convertTimeRange(startTimeStr, duration, tz) {
		const startDateTime = DateTime.fromFormat(startTimeStr, CONSTANTS.DATE_TIME_FORMATS.TIME_ONLY, { zone: tz })
		const endDateTime = startDateTime.plus({ minutes: duration })
		function formatTime(dateTime) {
			return dateTime.toLocal().toFormat(CONSTANTS.DATE_TIME_FORMATS.TIME_ONLY).replace(':00', '')
		}

		const startFormatted = formatTime(startDateTime)
		const endFormatted = formatTime(endDateTime)

		return `${startFormatted} - ${endFormatted}`
	}
	function formatDate(dateStr) {
		const date = DateTime.fromISO(dateStr)
		return date.toFormat('MMMM d, yyyy')
	}
</script>
