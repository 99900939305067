<template>
	<div :class="[inputClass, 'members-horizontal-list-container']">
		<template v-for="(member, index) in membersShow" :key="index">
			<AppUserImage
				:name="member?.full_name"
				:src="member?.profile_image"
				:color="member?.color"
				size="md"
				class="member-image"
				:style="{ zIndex: membersShow.length - index }"
			/>
		</template>
		<div class="total-members">{{ totalMembersShow }}</div>
	</div>
</template>
<script>
	export default {
		name: 'MembersHorizontalListCramped',
		props: {
			members: {
				type: Array,
			},
			totalMembers: {
				type: Number,
			},
			inputClass: {
				type: [Array, String],
			},
			circle: {
				type: Boolean,
				default: false,
			},
		},
		computed: {
			membersShow() {
				return this.members.splice(0, 7)
			},
			totalMembersShow() {
				return `${this.totalMembers} member${this.totalMembers === 1 ? '' : 's'}`
			},
		},
	}
</script>

<style lang="scss" scoped>
	.members-horizontal-list-container {
		@apply flex flex-row items-center justify-center relative w-fit;
		padding-left: 3px;
		.member-image {
			position: relative;
			width: 40px;
			height: 40px;
			margin-left: -10px;

			.member-image:nth-child(1) {
				left: 0;
			}
			.member-image:nth-child(2) {
				left: 10px;
			}
			.member-image:nth-child(3) {
				left: 20px;
			}
			.member-image:nth-child(4) {
				left: 30px;
			}
			.member-image:nth-child(5) {
				left: 40px;
			}
		}
	}
	.total-members {
		@apply ml-1.5;
	}
</style>
