<template>
	<Transition name="fade">
		<main
			ref="mainWrapper"
			class="m-0 p-0 h-full w-full overflow-scroll flex flex-col bg-stan-bg relative items-center z-[3]"
			:class="{ 'pointer-events-none': mobileMenuToggle || hasWizard }"
			key="main"
		>
			<CommunityOnboardingWizard
				v-if="shouldShowWizard"
				:create-post-element="createPostElement"
				:about-element="$refs.about"
				:links-element="$refs.links"
				@toggle-highlight-header="val => (headerHighlighted = val)"
				@change-step="val => (communityOnboardingWizardStep = val)"
			/>
			<div v-if="showSmallImageOnTop" class="relative">
				<div class="relative w-full flex flex-row justify-center" @mouseenter="displayButtons" @mouseleave="hideButtons">
					<div class="max-w-96">
						<SmallImage />
					</div>
				</div>
			</div>
			<div v-else-if="!showSmallImage" class="relative" @mouseenter="displayButtons" @mouseleave="hideButtons">
				<Header :showButtons="showButtons" :isOwner="isOwner" :header-highlighted="headerHighlighted" />
			</div>
			<Transition name="fade" :key="hasCommunityBaseLoaded">
				<div class="w-full grow relative bg-stan-bg" v-if="hasCommunityBaseLoaded">
					<div class="max-w-7xl mx-auto">
						<div class="grid lg:grid-cols-3 grid-cols-2 gap-6 h-fit px-4 md:px-10">
							<div class="col-span-2 flex flex-col">
								<NavigationBar :tabs="tabs" @toggleMobileMenu="toggleMobileMenu" />
								<RouterView :id="`page-${routerKey}`" :key="`page-${routerKey}`" class="h-full page-wrapper" v-slot="{ Component }">
									<transition name="slide">
										<component :is="Component" />
									</transition>
								</RouterView>
							</div>

							<div v-if="!isMobile" class="col-span-1 flex flex-col w-full gap-4 mb-10">
								<div class="justify-center items-center p-2 flex flex-row gap-2 mt-2" v-if="!isSearchPage">
									<NotificationComponent @viewPost="redirectToPost" />
									<AppSearchInput class="w-full" @submit="search" />
								</div>
								<SmallImage v-if="showSmallImage" />
								<AboutComponent ref="about" />
								<PodcastComponent v-if="hasPodcastExperiment" ref="podcasts" />
								<WebinarsSection ref="webinars" />
								<LinksSection ref="links" />

								<div class="flex flex-col gap-6 w-full items-start ml-2 mt-4 sticky top-0">
									<AppButton v-if="canModifyMembers" icon="person" link color="light" @click="goToManageMembers"
										>Manage Members</AppButton
									>
									<AppButton icon="community" link color="light" @click="goToAllCommunities">My Communities</AppButton>
									<AppButton icon="settings" link color="light" @click="goToSettings">Settings</AppButton>
									<AppButton icon="logout" link color="light" @click="onLogoutClicked">Logout</AppButton>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="flex h-full w-full justify-center items-center">
					<SolidDotsSpinner :dot-size="10" color="var(--community-primary-color)" />
				</div>
			</Transition>

			<Transition name="slide">
				<div
					v-if="isMobile && mobileMenuToggle"
					class="mobile-menu-wrapper fixed h-full bg-stan-foreground pt-0 z-[9999] right-0 inset-y-0 flex flex-col max-w-sm overflow-y-scroll gap-12 grow pointer-events-auto overflow-x-hidden"
				>
					<div
						class="sticky top-0 pt-6 pb-6 md:top-0 bg-stan-foreground z-40 w-full flex flex-row flex-nowrap justify-between items-center -mb-12"
					>
						<div class="notification-container">
							<div v-if="isMobile && !isSearchPage" class="mt-0" size="xs" @click="rediectToSearch">
								<AppIcon src="search" class="color-stroke-primary" />
							</div>
						</div>
						<AppIcon src="x" @click="toggleMobileMenu" class="cursor-pointer" />
					</div>
					<div>
						<AboutComponent @modal-open="mobileModalOpen = true" />
						<PodcastComponen v-if="hasPodcastExperiment" ref="podcasts" />
						<WebinarsSection ref="webinars" />
						<LinksSection />
						<div class="flex flex-col gap-6 w-full items-start ml-2 mt-4 sticky top-0">
							<AppButton v-if="canModifyMembers" icon="person" link @click="goToManageMembers">Manage Members</AppButton>
							<AppButton icon="community" link @click="goToAllCommunities">My Communities</AppButton>
							<AppButton icon="settings" link @click="goToSettings">Settings</AppButton>
							<AppButton icon="logout" link color="light" @click="onLogoutClicked">Logout</AppButton>
						</div>
					</div>
				</div>
			</Transition>
			<UnsplashImagePickerModal ref="unsplashImagePickerModal" @close-modal="$emit('cancel-unsplash')" />
		</main>
	</Transition>
</template>
<script>
	import { useCommunityStore } from '@/stores/communities'
	import { useCommunityPublicStore } from '@/stores/public'
	import { useProductsStore } from '@/stores/products'
	import { useGlobalsStore } from '@/stores/globals'
	import { useAuthStore } from '@/stores/auth'
	import { useResourceStore } from '@/stores/resources'
	import constants from '@/global_helper/constants'
	import prepareStoresMixin from './PrepareStoreMixin'
	import mobileCheckMixin from './MobileCheckMixin'
	import { useElementsStore } from '@/stores/elements'
	import Clarity from '@microsoft/clarity'
	import SmallImage from '@/components/global/SmallImage.vue'
	import NotificationComponent from '@/components/resources/NotificationComponent.vue'
	import helpers from '@/global_helper/helpers'

	export default {
		name: 'MainContainer',
		mixins: [prepareStoresMixin, mobileCheckMixin],
		data() {
			return {
				headerHighlighted: false,
				searchInput: '',
				stackedModalOpen: 0,
				mentionMemberId: undefined,
				mobileMenuToggle: false,
				currentScrollVals: {
					top: 0,
					height: 0,
					offsetHeight: 0,
				},
				showButtons: false,
				createPostElement: null,
				canShowButtons: true,
				communityOnboardingWizardStep: null,
				mobileModalOpen: false,
			}
		},
		async created() {
			this.globalStore.setPageLoading(false)
		},
		computed: {
			elementsStore() {
				return useElementsStore()
			},
			communityStore() {
				return useCommunityStore()
			},
			canModifyMembers() {
				return this.communityStore.canModifyMembers
			},
			communityPublicStore() {
				return useCommunityPublicStore()
			},
			hasPodcastExperiment() {
				return this.communityPublicStore.experiments.includes(138)
			},
			productsStore() {
				return useProductsStore()
			},
			resourceStore() {
				return useResourceStore()
			},
			authStore() {
				return useAuthStore()
			},
			globalStore() {
				return useGlobalsStore()
			},
			navigationType() {
				return this.globalStore.navigationType
			},
			showNavigation() {
				return this.globalStore.showNavigation
			},
			shouldShowWizard() {
				return this.isOwner && !this.isMobile && this.createPostElement && this.$refs.about && this.$refs.links
			},
			communities() {
				return this.communityStore.communities
			},
			self() {
				return this.communityStore.self
			},
			isOwner() {
				return this.communityStore.isOwner
			},
			canModifyChannels() {
				return this.communityStore.canModifyChannels
			},
			isCreatorTeamMember() {
				return ['owner', 'moderator', 'admin'].indexOf(this.communityStore.self.type) > -1
			},
			currentCommunity() {
				return this.communityStore.currentCommunity
			},
			currentCommunityAccess() {
				return this.currentCommunity.data?.settings?.dm_level
			},
			currentChannel() {
				return this.communityStore.currentChannel
			},
			currentRouteName() {
				return this.$route.name
			},
			routerKey() {
				if (this.isOnPostsPage) {
					return 'posts-view'
				}
				if (this.isOnResourcePage) {
					return 'resources-view'
				}
				return this.currentRouteName
			},
			communityMembers() {
				return this.communityStore.members
			},
			isOnPostsPage() {
				return this.currentRouteName === constants.ROUTE_NAMES.HOME || this.currentRouteName === constants.ROUTE_NAMES.POST
			},
			isOnResourcePage() {
				return this.currentRouteName === constants.ROUTE_NAMES.RESOURCES || this.currentRouteName === constants.ROUTE_NAMES.RESOURCE
			},
			mentionMemberDetails() {
				return this.communityStore.members[this.mentionMemberId]
			},
			showAddEditChannelModal() {
				return this.globalStore.showAddEditChannelModal
			},
			isEditingChannel() {
				return this.globalStore.isEditingChannel
			},
			channelUnreadStatus() {
				return this.communityStore.channels_unread_status
			},
			isActivityUnread() {
				return this.communityStore.isActivityUnread
			},
			isSearchPage() {
				if (['search'].includes(this.$route.name)) {
					return true
				}
				return false
			},
			shouldShowResources() {
				return (
					this.canModifyChannels ||
					(!this.canModifyChannels && this.resourceStore.resources.filter(r => r.status !== constants.RESOURCE_STATUSES.INACTIVE).length)
				)
			},
			tabs() {
				let tabs = [
					{ id: 'home', title: 'Community' },
					{ id: 'products', title: 'My Products' },
				]
				if (this.shouldShowResources) {
					tabs = [
						{ id: 'home', title: 'Community' },
						{ id: 'resources', title: 'Resources' },
						{ id: 'products', title: 'My Products' },
					]
				}

				return tabs
			},
			hasCommunityBaseLoaded() {
				return this.communityStore.self && this.communityPublicStore.hasLoaded
			},
			oldestMessageId() {
				// Check for oldest message based on current filtered tag
				const message_identifiers = Object.keys(this.communityStore.tagMessages).filter(
					messageId => this.communityStore.messages[messageId].status === constants.MESSAGE_STATUS.ACTIVE
				)

				const oldestMessageId = message_identifiers.map(messageId => parseInt(messageId)).sort((a, b) => a - b)[0]

				return message_identifiers.length ? oldestMessageId : null
			},
			scrollHandler() {
				const self = this
				return this.$debounce(ev => {
					const isScrollingDown = self.currentScrollVals.top < (ev.target?.scrollTop || 0)
					self.currentScrollVals.top = ev.target?.scrollTop || 0
					self.currentScrollVals.height = ev.target?.scrollHeight || 0
					self.currentScrollVals.offsetHeight = ev.target.offsetHeight
					const topPercent = self.currentScrollVals.top / self.currentScrollVals.height
					if (topPercent > 0.7 && isScrollingDown) {
						self.communityStore.getMessages(
							{
								maximum_message_id: self.oldestMessageId,
							},
							'append'
						)
					}
				}, 100)
			},
			hasWizard() {
				return this.shouldShowWizard && !['END', 'NOT_DOING'].includes(this.communityOnboardingWizardStep)
			},
			showSmallImage() {
				return this.communityStore.currentCommunity.data?.image_style === 'small'
			},
			showSmallImageOnTop() {
				return this.isMobile && this.showSmallImage
			},
		},
		methods: {
			goToSettings() {
				this.$router.push({ name: 'settings' })
			},
			goToManageMembers() {
				this.mobileMenuToggle = false
				this.$router.push({ name: 'members' })
			},
			goToAllCommunities() {
				this.mobileMenuToggle = false
				this.$router.push({ path: '/' })
			},
			onLogoutClicked() {
				this.authStore.logout()
				this.$router.push({ name: 'login' })
			},
			toggleMobileMenu() {
				this.mobileMenuToggle = !this.mobileMenuToggle
			},
			displayButtons() {
				if (this.communityOnboardingWizardStep !== constants.COMMUNITY_ONBOARDING.COMMUNITY_NAME) {
					return (this.showButtons = true)
				}
			},
			hideButtons() {
				if (this.communityOnboardingWizardStep !== constants.COMMUNITY_ONBOARDING.COMMUNITY_NAME) this.showButtons = false
			},
			redirectToPost(messageId, commentId = null) {
				if (commentId) {
					this.$router.push({
						name: 'post',
						params: { messageId, commentId },
					})
				} else {
					this.$router.push({
						name: 'post',
						params: { messageId },
					})
				}
			},
			rediectToSearch() {
				this.mobileMenuToggle = false
				this.$router.push({ name: 'search' })
			},
			redirectToResource(resourceId) {
				if (this.isMobile) {
					this.toggleMobileMenu()
				}
				if (resourceId)
					this.$router.push({
						name: 'resource',
						params: {
							resourceId: resourceId,
						},
					})
				else this.$router.push({ name: 'resources' })
			},
			checkSetupCommunityName() {
				// If the community name has not been setup, perform false check since it will be undefined while loading
				if (
					this.communityStore &&
					this.communityStore.onboardingState &&
					this.communityStore.onboardingState[constants.COMMUNITY_ONBOARDING.COMMUNITY_NAME] == false
				) {
					this.$router.push({ name: 'setup' })
				}
			},
		},
		mounted() {
			this.checkSetupCommunityName()

			if (this.$refs.mainWrapper) {
				this.$refs.mainWrapper.addEventListener('scroll', this.scrollHandler)
			}
			this.createPostElement = this.elementsStore.createPostElement
		},
		onUnmounted() {
			this.$refs.mainWrapper.removeEventListener('scroll', this.scrollHandler)
		},
		watch: {
			'elementsStore._create_post_element': {
				handler() {
					this.createPostElement = this.elementsStore.createPostElement
				},
			},
			communityOnboardingWizardStep: {
				handler() {
					if (this.communityOnboardingWizardStep === constants.COMMUNITY_ONBOARDING.WALKTHROUGH_RESOURCES) {
						this.$nextTick(() => {
							this.elementsStore.setResourcesWithWizard(true)
							this.$router.push({
								name: 'resources',
							})
						})
					}
				},
			},
			hasCommunityBaseLoaded: {
				handler() {
					this.checkSetupCommunityName()
				},
			},
			'$route.name': {
				immediate: true,
				handler(newVal) {
					if (newVal && this.self?.data) {
						Clarity.identify(this.self.community_member_id, this.globalStore.sessionUUID, newVal, this.self.data.full_name)
					}
				},
			},
		},
	}
</script>
<style lang="scss">
	.notification-container {
		padding: 20px;
		position: absolute;
		top: 0;
		right: 0;
		@apply flex flex-row gap-2;
	}
	.mobile-menu-wrapper {
		@apply py-6 px-3;
		min-width: 320px;
	}
</style>
