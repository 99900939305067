<template>
	<div class="posts-list-container">
		<InlinePost :messageId="message.message_id" v-for="message in pinnedMessages" :key="`${message && message.message_id}${message.timestamp}`" />

		<InlinePost
			:message="message"
			:messageId="message.message_id"
			v-for="message in activeMessages"
			:key="`${message && message.message_id}${message.timestamp}`"
		/>
		<div v-if="empty" class="empty-post" @click="$emit('empty-click')" id="empty-post">
			<AppIcon src="people" class="color-text-light lg" />
			Introduce yourself to your community!
		</div>
	</div>
</template>
<script setup>
	import { computed } from 'vue'
	import { useCommunityStore } from '@/stores/communities'
	import constants from '@/global_helper/constants'

	const props = defineProps({
		tag_id: {
			type: String,
			required: false,
		},
	})
	const communityStore = useCommunityStore()
	const currentChannel = computed(() => communityStore.currentChannel.channel_id)
	const messagesRaw = computed(() => communityStore.tagMessages)
	const activeMessages = computed(() => {
		return Object.keys(messagesRaw.value)
			.map(key => messagesRaw.value[key])
			.sort((a, b) => b.timestamp - a.timestamp)
			.filter(message => message.status === constants.MESSAGE_STATUS.ACTIVE)
			.filter(message => {
				if (!props.tag_id) {
					return true
				}
				return message.tag_id == props.tag_id
			})
	})
	const pinnedMessages = computed(() => {
		return Object.keys(messagesRaw.value)
			.map(key => messagesRaw.value[key])
			.sort((a, b) => b.timestamp - a.timestamp)
			.filter(message => message.status === constants.MESSAGE_STATUS.PINNED)
			.filter(message => {
				if (!props.tag_id) {
					return true
				}
				return message.tag_id == props.tag_id
			})
	})
	const empty = computed(() => activeMessages.value.length === 0 && pinnedMessages.value.length === 0)
</script>

<style lang="scss" scoped>
	.posts-list-container {
		@apply w-full overflow-x-visible flex flex-col gap-7 pb-56;

		.empty-post {
			@apply box-border border-dashed rounded-xl border-stan-gray-dark border;
			@apply w-full p-24 flex flex-col justify-center items-center;
			@apply text-stan-text-light text-base -tracking-32 leading-6;
			cursor: pointer;
		}
	}
</style>
