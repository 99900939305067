<template>
	<button
		class="app-button"
		:class="[
			sizeClass,
			{ rounded: rounded },
			{ disabled: disabled },
			{ outline: outline },
			{ link: link },
			{ loading: loading },
			`button-color-${color}`,
		]"
		:disabled="disabled"
		@click.prevent="handleClick"
	>
		<SolidDotsSpinner v-if="loading" :dot-size="dotSize" :color="loaderColor" class="loader" />
		<div class="button-content" :class="[contentSizeClass, { hide: loading }]">
			<div v-if="icon" class="button-icon">
				<AppIcon class="icon-svg" :src="icon" />
			</div>
			<slot class="no-wrap"></slot>
		</div>
	</button>
</template>
<script>
	export default {
		name: 'AppButton',
		emits: ['click'],
		props: {
			disabled: { type: Boolean, default: false },
			outline: { type: Boolean, default: false },
			link: { type: Boolean, default: false },
			rounded: { type: Boolean, default: false },
			size: { type: String, default: 'md' }, // sm/small, md/medium, lg/large, xl
			loading: { type: Boolean, default: false },
			icon: { type: String, default: '' },
			color: {
				type: String,
				default: 'primary',
				validator: value => ['primary', 'dark', 'danger', 'gray', 'light', 'stan-primary'].includes(value),
			},
		},
		data() {
			return {
				contentWidth: 10,
				sizeMapping: {
					default: {
						dotSize: 10,
						sizeClass: 'button-md',
						contentSizeClass: 'h5 text-bold',
					},
					xs: {
						dotSize: 5,
						sizeClass: 'button-xs',
						contentSizeClass: 'para-3 text-bold',
					},
					xtrasmall: {
						dotSize: 5,
						sizeClass: 'button-xs',
						contentSizeClass: 'para-3 text-bold',
					},
					sm: {
						dotSize: 7,
						sizeClass: 'button-sm',
						contentSizeClass: 'para-2 text-bold',
					},
					small: {
						dotSize: 7,
						sizeClass: 'button-sm',
						contentSizeClass: 'para-3 text-bold',
					},
					md: {
						dotSize: 10,
						sizeClass: 'button-md',
						contentSizeClass: 'h5 text-bold',
					},
					medium: {
						dotSize: 10,
						sizeClass: 'button-md',
						contentSizeClass: 'h5 text-bold',
					},
					lg: {
						dotSize: 12,
						sizeClass: 'button-lg',
						contentSizeClass: 'h4 text-bold',
					},
					large: {
						dotSize: 12,
						sizeClass: 'button-lg',
						contentSizeClass: 'h4 text-bold',
					},
					xl: {
						dotSize: 14,
						sizeClass: 'button-xl',
						contentSizeClass: 'h3 text-bold',
					},
				},
			}
		},
		computed: {
			loaderColor() {
				if (!this.outline && !this.link) return 'var(--stan-white)'
				switch (this.color) {
					case 'stan-primary':
						return 'var(--stan-primary-primary-color)'
					case 'danger':
						return 'var(--stan-negative-primary-color)'
					case 'dark':
						return 'var(--stan-text-primary-color)'
					case 'light':
						return 'var(--stan-text-light-color)'
					default:
						return 'var(--community-primary-color)'
				}
			},
			dotSize() {
				return this.sizeMapping[this.size]?.dotSize ?? this.sizeMapping.default.dotSize
			},
			sizeClass() {
				return this.sizeMapping[this.size]?.sizeClass ?? this.sizeMapping.default.sizeClass
			},
			contentSizeClass() {
				return this.sizeMapping[this.size]?.contentSizeClass ?? this.sizeMapping.default.contentSizeClass
			},
		},
		methods: {
			handleClick(e) {
				if (!this.disabled && !this.loading) {
					this.$emit('click', e)
				}
			},
		},
	}
</script>
<style lang="scss" scoped>
	.app-button {
		.h5 {
			margin-bottom: 0;
			font-size: 0.875rem;
		}
		&,
		:deep(.button-icon) {
			&,
			.icon-svg path {
				@apply transition duration-200 ease-in-out;
			}
		}
	}
	@mixin linkButton($mainColor, $hoverColor) {
		background-color: transparent;
		color: $mainColor;
		border-color: transparent;

		&.disabled {
			pointer-events: none;
			opacity: 0.5;
		}

		.button-content {
			:deep(.button-icon) {
				.icon-svg {
					path {
						fill: $mainColor;
					}
				}
			}
		}
		// &:hover {
		// 	color: $hoverColor;

		// 	:deep(.button-icon) {
		// 		.icon-svg {
		// 			path {
		// 				fill: $hoverColor;
		// 			}
		// 		}
		// 	}
		// }
	}

	@mixin outlineButton($mainColor, $hoverColor, $disabledColor) {
		background-color: transparent;
		color: $mainColor;
		border-color: $mainColor;

		// &:hover {
		// 	background-color: $hoverColor;
		// }

		&.disabled {
			pointer-events: none;
			color: $disabledColor;
			border-color: $disabledColor;
			.button-content {
				:deep(.button-icon) {
					.icon-svg {
						path {
							fill: $disabledColor !important;
						}
					}
				}
			}
		}

		.button-content {
			:deep(.button-icon) {
				.icon-svg {
					path {
						fill: $mainColor;
					}
				}
			}
		}

		&.hide-outline {
			border: none;

			// &:hover {
			// 	background: transparent;
			// }
		}
	}

	.app-button {
		position: relative;
		overflow: hidden;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 8px;
		border: 1px solid var(--community-primary-color);
		background-color: var(--community-primary-color);
		color: var(--community-primary-d-text-color);
		cursor: pointer;

		&.loading {
			pointer-events: none;
		}

		// &:hover {
		// 	border-color: var(--community-primary-strike-color);
		// 	// background-color: var(--community-primary-strike-color);
		// }

		&.disabled {
			pointer-events: none;
			border-color: var(--community-primary-soft-color);
			background-color: var(--community-primary-soft-color);
			.button-content {
				:deep(.button-icon) {
					.icon-svg {
						path {
							fill: var(--community-primary-d-text-color) !important;
						}
					}
				}
			}
		}

		.button-content {
			@apply flex items-center justify-center w-full gap-1.5 leading-4;

			&.hide {
				opacity: 0%;
			}

			:deep(.button-icon) {
				aspect-ratio: 1;

				.icon-svg {
					path {
						fill: var(--community-primary-d-text-color);
					}
				}
			}
		}

		&.outline {
			padding: 0;
			@include outlineButton(var(--community-primary-color), var(--community-primary-light-color), var(--community-primary-soft-color));
		}

		&.link {
			border-radius: 0;
			.button-content {
				line-height: 1.3;
			}
			padding: 0 !important;
			@include linkButton(var(--community-primary-color), var(--community-primary-light-color));
		}

		&.button-sm {
			&:not(.link) {
				min-width: 100px;
				min-height: 32px;
			}
			padding: 8px 10px 8px 10px;
			gap: 5px;
			font-size: 1rem;
			&.rounded {
				border-radius: 16px !important;
			}
			:deep(.button-icon) {
				.icon-svg {
					height: 16px;
					width: 16px;
				}
			}
		}

		&.button-xs {
			height: auto;
			padding: 8px 11px 8px 10px;
			gap: 5px;
			&.rounded {
				border-radius: 8px !important;
			}
			:deep(.button-icon) {
				.icon-svg {
					height: 15px;
					width: 15px;
				}
			}
		}

		&.button-md {
			&:not(.link) {
				min-width: 133px;
				height: 42px;
			}
			padding: 15px;
			gap: 5px;
			&.rounded {
				border-radius: 21px !important;
			}
			:deep(.button-icon) {
				.icon-svg {
					height: 18px;
					width: 18px;
				}
			}
		}

		&.button-lg {
			&:not(.link) {
				min-width: 135px;
			}
			height: 50px;
			padding: 15px 20px 15px 18px;
			gap: 8px;
			&.rounded {
				border-radius: 25px !important;
			}
			:deep(.button-icon) {
				.icon-svg {
					height: 24px;
					width: 24px;
				}
			}
		}

		&.button-xl {
			&:not(.link) {
				min-width: 183px;
			}
			height: 60px;
			padding: 20px 25px;
			gap: 5px;
			&.rounded {
				border-radius: 30px !important;
			}
			:deep(.button-icon) {
				.icon-svg {
					height: 28px;
					width: 28px;
				}
			}
		}
		&.button-color-stan-primary {
			background-color: var(--stan-primary-primary-color);
		}
		&.button-color-danger {
			background-color: var(--stan-negative-primary-color);
			border-color: var(--stan-negative-primary-color);
			color: var(--stan-white);
			// &:hover {
			// 	background-color: var(--stan-negative-strike-color);
			// 	color: #fff;
			// }

			&.disabled {
				color: var(--stan-negative-soft-color);
				border-color: var(--stan-negative-soft-color);
			}

			.button-content {
				:deep(.button-icon) {
					.icon-svg {
						path {
							fill: var(--stan-negative-primary-color);
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-negative-primary-color), var(--stan-white), var(--stan-negative-soft-color));
			}
			&.link {
				@include linkButton(var(--stan-negative-primary-color), var(--stan-negative-soft-color));
			}
		}

		&.button-color-dark {
			background-color: var(--stan-text-primary-color);
			border-color: var(--stan-text-primary-color);

			// &:hover {
			// 	background-color: var(--community-primary-strike-color);
			// 	border-color: var(--community-primary-strike-color);
			// 	color: #fff;
			// }

			&.disabled {
				color: var(--community-primary-soft-color);
				border-color: var(--community-primary-soft-color);
			}

			.button-content {
				:deep(.button-icon) {
					.icon-svg {
						path {
							fill: var(--stan-text-primary-color);
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-text-primary-color), var(--stan-text-light-color), var(--community-primary-soft-color));
			}
			&.link {
				@include linkButton(var(--stan-text-primary-color), var(--stan-text-light-color));
			}
		}
		&.button-color-light {
			background-color: var(--stan-text-light-color);
			border-color: var(--stan-text-light-color);

			// &:hover {
			// 	// background-color: var(--community-primary-strike-color);
			// 	border-color: var(--community-primary-strike-color);
			// 	color: #fff;
			// }

			&.disabled {
				color: var(--community-primary-soft-color);
				border-color: var(--community-primary-soft-color);
			}

			.button-content {
				:deep(.button-icon) {
					.icon-svg {
						path {
							fill: var(--stan-text-light-color);
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-text-light-color), var(--community-primary-light-color), var(--community-primary-soft-color));
			}
			&.link {
				@include linkButton(var(--stan-text-light-color), var(--stan-text-primary-color));
			}
		}
		.loader {
			position: absolute;
		}

		&.button-color-gray {
			background-color: var(--stan-gray-light-color);
			border-color: var(--stan-gray-light-color);

			// &:hover {
			// 	background-color: var(--stan-gray-primary-color);
			// 	border-color: var(--stan-gray-primary-color);
			// 	color: #fff;
			// }

			&.disabled {
				color: var(--stan-gray-soft-color);
				border-color: var(--stan-gray-soft-color);
			}

			.button-content {
				:deep(.button-icon) {
					.icon-svg {
						path {
							fill: var(--stan-text-light-color);
						}
					}
				}
			}
			&.outline {
				@include outlineButton(var(--stan-gray-light-color), var(--stan-gray-primary-color), var(--stan-gray-light-color));
			}
			&.link {
				@include linkButton(var(--stan-gray-light-color), var(--stan-gray-dark-color));
			}
		}
	}
</style>
