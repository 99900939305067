<template>
	<AppModal
		ref="addMemberModal"
		title="Add Member"
		subheading="Invite others to join this discussion"
		:loading="loading"
		@close="close"
		class="add-member-modal"
	>
		<AppInput class="mb-2" v-model="full_name" label="Full name" placeholder="ex: John Doe" :error="v$.full_name.$errors" :maxlength="50" />
		<AppInput class="mb-0" v-model="emailVal" label="Email Address" placeholder="ex: hello@email.com" :error="v$.emailVal.$errors" />
		<div class="w-full mb-2">
			<p class="font-semibold text-xs text-stan-text-primary">Role</p>
			<AppSelectDropdown :options="roleOptions" fullWidth class="mt-2" @value-selected="roleSelected" :initialValue="roleOptions[2]?.value" />
		</div>
		<AppButton class="mt-4" @click="addMember" :loading="loading">Add Member</AppButton>
	</AppModal>
</template>

<script setup>
	import { capitalize } from 'lodash'
	import { ref, computed } from 'vue'
	import { useVuelidate } from '@vuelidate/core'

	import { required, email, helpers, maxLength } from '@vuelidate/validators'
	import { notify } from '@kyvg/vue3-notification'
	import { useCommunityStore } from '@/stores/communities'

	const loading = ref(false)
	const full_name = ref('')
	const emailVal = ref('')
	const addMemberModal = ref(null)
	const communityStore = computed(() => useCommunityStore())
	const props = defineProps({
		roleOptions: {
			type: Array,
			default: [],
		},
	})
	const emit = defineEmits('user_added')
	const role = ref(props.roleOptions[2]?.value || 'member')

	const validationRules = {
		emailVal: { required: helpers.withMessage('Email is required', required), email: helpers.withMessage('Email is not valid', email) },
		full_name: {
			required: helpers.withMessage('Full name is required', required),
			length: helpers.withMessage("Please ensure member's full name is up to 50 characters long", maxLength(50)),
		},
	}

	const v$ = useVuelidate(validationRules, {
		emailVal,
		full_name,
	})

	const roleSelected = val => {
		role.value = val
	}

	const show = () => {
		v$.value.$reset()
		addMemberModal.value.show()
		resetForm()
	}
	const close = () => {
		addMemberModal.value.hide()
	}
	const resetForm = () => {
		emailVal.value = ''
		full_name.value = ''
	}

	const addMember = async () => {
		const isFormCorrect = await v$.value.$validate()
		if (!isFormCorrect) {
			return
		}
		const payload = {
			email: emailVal.value,
			full_name: full_name.value,
			type: role.value,
		}
		try {
			loading.value = true
			await communityStore.value.inviteNewMember(payload)
			notify({
				type: 'Success',
				title: `Success!`,
				text: `Added ${capitalize(role.value)}.`,
			})
		} catch (error) {
			notify({ type: 'error', text: 'Something went wrong when adding your member, please try again.' })
		}
		close()
		loading.value = false
	}

	defineExpose({
		show,
	})
</script>

<style lang="scss" scoped>
	.add-member-modal {
		:deep(.header) {
			@apply mb-0;
		}
		:deep(.app-card-container) {
			overflow: unset;
		}
	}
</style>
