/* eslint-disable no-underscore-dangle */
import { defineStore } from 'pinia'
import axios from 'axios'
import { DateTime } from 'luxon'
import constants from '@/global_helper/constants'
import helpers from '@/global_helper/helpers'
import { SSE_EVENT_TYPE, SSE_CHANNEL_TYPE_PREFIXES } from '@/common/constants/sseEvents'
import { addOrIncrementReactionInStateIfNotAlreadyPresent, decrementReactionInStateIfNotAlreadyPresent, reactedInState } from './reactions'
import { deleteMessageInState, updateMessageDataInState } from './messages'
import { updateNotificationInState } from './notifications'
import { addChannelParticipantInState, createEditChannelInState } from './channels'
import { addMessageToTagMessageIds } from './tags'

const POLLING_DISABLED = true

function standardizeDMChannel(dmChannel, self, members) {
	const channel = JSON.parse(JSON.stringify(dmChannel))
	const participantsWithoutSelf = channel.participants
		.filter(participant => self.community_member_id !== participant.community_member_id)
		.map(participant => members[participant.community_member_id])
		.filter(member => !!member)
	if (participantsWithoutSelf.length == 0) participantsWithoutSelf.push(self)
	const memberWithProfilePic = participantsWithoutSelf.find(member => member.data?.profile_image) || participantsWithoutSelf[0]
	if (memberWithProfilePic && participantsWithoutSelf.length > 1)
		channel.pretty_name = `${memberWithProfilePic.data.full_name}, ${participantsWithoutSelf
			.filter(member => member.community_member_id !== memberWithProfilePic.community_member_id)
			.map(member => member.data.full_name)
			.sort()
			.join(', ')}`
	else
		channel.pretty_name = participantsWithoutSelf
			.map(member => member.data.full_name)
			.sort()
			.join(', ')
	channel.image_member = memberWithProfilePic
	channel.participant_count = participantsWithoutSelf.length
	channel.threads = {}
	return channel
}

function handleStreamEvents(ev, store) {
	if (helpers.hasTargetedEventType(ev.type, SSE_CHANNEL_TYPE_PREFIXES)) {
		return
	}

	const event = JSON.parse(JSON.stringify(ev.data))
	const { event_name: eventName, metadata, data: eventData } = event

	// Get the community that the event is for
	let communitySlug = undefined
	const metaCommunityIds = metadata?.community_ids || []
	if (metaCommunityIds.length > 0) {
		communitySlug = Object.keys(store._communities).find(slug => {
			return store._communities[slug]?.community_id == metaCommunityIds[0]
		})
	}

	switch (eventName) {
		case SSE_EVENT_TYPE.MESSAGE_SENT: {
			updateMessageDataInState(eventData, store)
			addMessageToTagMessageIds(eventData, store)
			store.markChannelAsRead()
			break
		}
		case SSE_EVENT_TYPE.CREATE_CHANNEL: {
			createEditChannelInState(eventData, store)
			break
		}
		case SSE_EVENT_TYPE.CHANGE_CHANNEL_NAME: {
			createEditChannelInState(eventData, store)
			break
		}
		case SSE_EVENT_TYPE.ADD_CHANNEL_PARTICIPANT: {
			addChannelParticipantInState(eventData, store)
			break
		}
		case SSE_EVENT_TYPE.DELETE_MESSAGE: {
			deleteMessageInState(eventData, store)
			break
		}
		case SSE_EVENT_TYPE.ADD_REACTION: {
			const { reaction, message } = eventData
			// Added to remove jitter -> if member id returns to be the currently logged in member, ignore reaction since its already rendered on browser
			if (store?.self?.community_member_id === reaction.member_id) break
			const newMessage = addOrIncrementReactionInStateIfNotAlreadyPresent(message, reaction, store)
			if (newMessage) {
				updateMessageDataInState(newMessage, store)
			}
			break
		}
		case SSE_EVENT_TYPE.REMOVE_REACTION: {
			const { reaction, message } = eventData
			// Added to remove jitter -> if member id returns to be the currently logged in member, ignore reaction since its already rendered on browser
			if (store?.self?.community_member_id === reaction.member_id) break
			const newMessage = decrementReactionInStateIfNotAlreadyPresent(message, reaction, store)

			if (newMessage) {
				updateMessageDataInState(newMessage, store)
			}
			break
		}
		case SSE_EVENT_TYPE.DATA_UPDATED: {
			// Update data for correct community
			if (communitySlug && store._communities[communitySlug]) {
				Object.assign(store._communities[communitySlug].data, eventData)
			}

			break
		}
		case SSE_EVENT_TYPE.NEW_NOTIFICATION: {
			updateNotificationInState(eventData, store)
			break
		}
		default: {
			break
		}
	}
}

function sortMessages(messages) {
	return messages.sort((messageA, messageB) => {
		const messageAId =
			messageA.threadMessages && Object.keys(messageA.threadMessages).length > 0
				? Object.keys(messageA.threadMessages).slice(-1)[0]
				: messageA.message_id
		const messageBId =
			messageB.threadMessages && Object.keys(messageB.threadMessages).length > 0
				? Object.keys(messageB.threadMessages).slice(-1)[0]
				: messageB.message_id
		return messageBId - messageAId
	})
}

export const useCommunityStore = defineStore('communities', {
	state: () => ({
		_can_play_notification: false,
		_communities: {},
		_current_community_slug: null,
		_current_channel_name: null,
		_current_thread_id: null,
		_current_tag_id: null, // default null is the all channel
		_is_current_thread_loading: false,
		_self: {},
		_channels_unread: {},
		_channels_unread_polling_interval: null,
		_isChannelsUnReadPolling: false,
		_messages_later: { results: {}, count: 0 },
		_skipMessageLaterUpdate: {},
		_isMessagesLaterPolling: false,
		_messages_later_polling_interval: null,
		_channel_polling_interval: null,
		_isChannelMessagePolling: false,
		_thread_polling_interval: null,
		_isThreadMessagePolling: false,
		_current_community_load_status: {
			channels: false,
			dms: false,
			members: false,
			self: false,
			unread_status: false,
		},
		_current_community_load_inital_messages_status: false,
		_sendingMessage: false,
		_skipMessageUpdate: {},
		_mentioned_message_ids: {},
		_threads_part_of: {},
		_activity_polling_interval: null,
		_isActivityPolling: false,
		_is_activity_unread: false,
		_obfuscatedId: null,
	}),
	getters: {
		communities: state => state._communities,
		currentCommunity: state => state._communities[state._current_community_slug] || {},
		currentChannel: state =>
			(state._current_channel_name && state._communities[state._current_community_slug]?.channels[state._current_channel_name]) || {},
		currentThread: state =>
			(state._current_channel_name &&
				state._current_thread_id &&
				state._communities[state._current_community_slug]?.channels[state._current_channel_name]?.messages[state._current_thread_id]) ||
			{},

		isCurrentThreadLoading: state => state._is_current_thread_loading,
		channelMembers: state => {
			const members = {}
			state.currentChannel?.participants?.forEach(participant => {
				if (state.members[participant.community_member_id])
					members[participant.community_member_id] = state.members[participant.community_member_id]
			})
			return members
		},
		members: state => state._communities[state._current_community_slug]?.members || {},
		activeMembers: state => {
			const allMembers = state._communities[state._current_community_slug]?.members || {}
			const aa = Object.keys(allMembers)
				.map(key => ({ ...allMembers[key], id: key }))
				.filter(({ status }) => status == constants.MEMBER_STATUS.ACTIVE)

			return aa
		},
		channels: state => state._communities[state._current_community_slug]?.channels || {},
		tags: state => state._communities[state._current_community_slug]?.tags || {},
		currentTag: state => state._current_tag_id,
		// Used to keep track of the messages tied to a specfic tag. `all` is `null` in this object
		tagMessageIds: state => state._communities[state._current_community_slug].tagMessageIds[state._current_tag_id] || [],
		communityIdToSlugMap: state => {
			const communities = state._communities
			const communitySlugs = Object.keys(communities)
			const communityIdSlugMap = {}
			communitySlugs.forEach(slug => (communityIdSlugMap[communities[slug].community_id] = slug))

			return communityIdSlugMap
		},
		obfuscatedId: state => state._obfuscatedId,
		channelIdToNameMap: state => {
			const channels =
				(state._current_community_slug &&
					state._communities[state._current_community_slug] &&
					state._communities[state._current_community_slug].channels) ||
				{}
			const channelNames = Object.keys(channels)
			const channelIdNameMap = {}
			channelNames.forEach(channelName => (channelIdNameMap[channels[channelName].channel_id] = channelName))
			return channelIdNameMap
		},
		currentChannelThreads: state =>
			(state._current_channel_name && state._communities[state._current_community_slug]?.channels[state._current_channel_name]?.threads) || {},
		channels_unread_status: state => state._channels_unread,
		messages: state =>
			(state._current_channel_name && state._communities[state._current_community_slug]?.channels[state._current_channel_name]?.messages) || {},
		// The specfic messages related to the tag. Ordered based on the tagMessageIds
		tagMessages: self =>
			self.tagMessageIds.reduce((out, messageId) => {
				// Account for messages that may have been deleted
				if (self.messages[messageId]) {
					out[messageId] = self.messages[messageId]
				}
				return out
			}, {}),
		notifications: state => state._communities[state._current_community_slug]?.notifications || {},
		threadMessages: state =>
			(state._current_channel_name &&
				state._current_thread_id &&
				state._communities[state._current_community_slug]?.channels[state._current_channel_name]?.threads[state._current_thread_id]
					?.messages) ||
			{},

		self: state => state._self,
		isOwner: state => state.self.type === 'owner',
		isAdmin: state => state.self.type === 'admin',
		isModerator: state => state.self.type === 'moderator',
		canModifyMembers: state => ['owner', 'admin'].indexOf(state.self.type) > -1,
		canModifyChannels: state => ['owner', 'admin'].indexOf(state.self.type) > -1,
		canModifyMessages: state => ['owner', 'admin', 'moderator'].indexOf(state.self.type) > -1,
		hasCommunityLoaded: state => Object.values(state._current_community_load_status).reduce((loaded, val) => loaded && val, true),
		hasCommunityInitalMessagesLoaded: state => !!state._current_community_load_inital_messages_status,
		messagesForLater: state => state._messages_later.results,
		messagesForLaterCount: state => state._messages_later.count,
		mentionedMessageIds: state => state._mentioned_message_ids,
		onboardingState: state => {
			const currentCommunity = state._communities[state._current_community_slug] || {}
			return currentCommunity?.data?.onboarding_state || {}
		},
		threadsPartOf: state => state._threads_part_of,
		activityItems: state => {
			let unreadMessages = []
			let messages = []
			const activity = state._communities[state._current_community_slug].activity
			Object.keys(activity).forEach(messageId => {
				const messageBasic = activity[messageId]
				const channelName = state.channelIdToNameMap[messageBasic.channel_id]

				const message =
					(messageBasic.parent_message_id
						? state.channels[channelName]?.threads[messageBasic.parent_message_id]?.messages[messageId]
						: state.channels[channelName].messages[messageId]) || {}
				let itemToStore = null
				let isUnread = false
				switch (messageBasic.type) {
					case 'mention':
						if (message) {
							message.type = messageBasic.type
							itemToStore = message
							isUnread = message.delivery_status === 'sent'
						}
						break
					case 'thread':
						const threadMessages = state.channels[channelName]?.threads[messageId]?.messages || {}
						if (message)
							itemToStore = {
								message_id: messageBasic.message_id,
								type: messageBasic.type,
								thread: message,
								threadMessages,
							}
						isUnread =
							message.delivery_status === 'sent' ||
							Object.values(threadMessages).reduce(
								(hasUnread, threadMessage) => threadMessage.delivery_status === 'sent' || hasUnread,
								false
							)
						break
				}
				if (itemToStore.message_id)
					if (isUnread) unreadMessages.push(itemToStore)
					else messages.push(itemToStore)
			})
			unreadMessages = sortMessages(unreadMessages)
			messages = sortMessages(messages)
			return [...unreadMessages, ...messages]
		},
		isActivityUnread: state => {
			let isUnread = false
			for (const messageId in state.activityItems) {
				const activityItem = state.activityItems[messageId]
				if (activityItem.type === 'mention') {
					if (activityItem.delivery_status === 'sent') {
						isUnread = true
						break
					}
				} else if (activityItem.type === 'thread') {
					const unread = Object.keys(activityItem.threadMessages).reduce(
						(ur, threadMessageId) => ur || activityItem.threadMessages[threadMessageId].delivery_status === 'sent',
						false
					)
					if (unread) {
						isUnread = true
						break
					}
				}
			}
			return isUnread
		},
	},
	actions: {
		getChannelThread(channelName) {
			return this._communities[this._current_community_slug]?.channels[channelName]?.threads
		},
		playNotificationSound() {
			try {
				if (this._can_play_notification) this.$commonHelper.playNotificationSound()
			} catch (err) {
				// do nothing as it might be that the user hasn't interacted with the application yet and autoplay error is thrown
			}

			this._can_play_notification = false
			setTimeout(() => {
				this._can_play_notification = true
			}, 5000)
		},
		async getAllCommunitiesBasicData() {
			return new Promise((resolve, reject) => {
				axios
					.get('v1/communities')
					.then(response => {
						resolve(response.data.communities)
					})
					.catch(err => {
						console.error(err)
						reject(err)
					})
			})
		},
		getObfuscatedId() {
			return new Promise((resolve, reject) => {
				axios
					.get(`/v1/rss/${this._current_community_slug}/link`)
					.then(response => {
						this._obfuscatedId = response.data.obfuscated_member_id
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		getCommunity() {
			return new Promise((resolve, reject) => {
				axios
					.get(`v1/communities/${this._current_community_slug}`)
					.then(response => {
						const community = response.data.community
						if (!this._communities[this._current_community_slug]) {
							community.activity = {}
							this._communities[this._current_community_slug] = community
						}
						this.$sse.on('message', ev => {
							handleStreamEvents(ev, this)
						})

						// We have community tags set them here
						const tags = this._communities[this._current_community_slug].tags
						if (tags) {
							this._communities[this._current_community_slug].tagMessageIds =
								this._communities[this._current_community_slug].tagMessageIds || {}
							Object.keys(tags).forEach(tagId => (this._communities[this._current_community_slug].tagMessageIds[tagId] = []))
						}
						resolve(this._communities)
					})
					.catch(error => {
						console.error(error)
						reject(error)
					})
			})
		},
		async setCurrentCommunity(communitySlug) {
			this._current_community_slug = communitySlug
			if (!this._communities[communitySlug]) {
				await this.getCommunity()
			}

			const currCommunity = this._communities[communitySlug]
			if (currCommunity) {
				this._current_channel_name = null
				this._current_thread_id = null
				this._channels_unread = {}
				this.getSelf()
				// this.getChannelUnreadStatuses()
				this.getMembers()
				this.getObfuscatedId()
				// this.getDMChannels()
				this.getChannels().then(() => {
					// Default channel. Since we only use one channel for now, avoid leaving space for channel name to not load
					this.setCurrentChannel(constants.FORUM_CHANNEL)
				})
				this.getNotifications()
				// this.triggerChannelUnreadStatusPolling()
				// TODO: Bring back once we are ready
				// this.triggerMessageLaterPolling()
				this._current_community_load_status = {
					channels: false,
					dms: true,
					members: false,
					self: false,
					unread_status: true,
				}
				this._current_community_load_inital_messages_status = false
			} else {
				this._current_community_slug = null
			}
		},
		removeCommunityMember(memberId) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`v1/communities/${this._current_community_slug}/members/${memberId}`, {})
					.then(() => {
						delete this._communities[this._current_community_slug].members[memberId]
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		getMembers() {
			return new Promise((resolve, reject) => {
				if (!this._current_community_slug) reject('No Community Found')
				axios
					.get(`v1/communities/${this._current_community_slug}/members`)
					.then(response => {
						this._communities[this._current_community_slug].members = response.data.members
						this._current_community_load_status.members = true
						resolve(this._communities[this._current_community_slug].members)
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		getSelf() {
			return new Promise((resolve, reject) => {
				if (!this._current_community_slug) reject('No Community Found')
				axios
					.get(`v1/communities/${this._current_community_slug}/self`)
					.then(response => {
						this._self = response.data.self

						resolve(this._self)
					})
					.catch(error => {
						console.error(error)
						reject()
					})
					.finally(() => {
						this._current_community_load_status.self = true
					})
			})
		},
		async createEditChannel(payload) {
			const response = await axios.put(`v1/communities/${this._current_community_slug}/channels`, payload)
			const channel = response.data.channel
			this._communities[this._current_community_slug].channels[channel.name] = channel
			return createEditChannelInState(channel, this)
		},
		getChannels() {
			return new Promise((resolve, reject) => {
				const currentCommunitySlug = this._current_community_slug
				if (!currentCommunitySlug) reject('No Community Found')
				axios
					.get(`v1/communities/${currentCommunitySlug}/channels`)
					.then(response => {
						if (!this._communities[currentCommunitySlug].channels) {
							this._communities[currentCommunitySlug].channels = {}
						}
						let threadLoadingCounter = 0
						for (const channelName in response.data.channels) {
							const channel = response.data.channels[channelName]
							channel.threads = {}
							this._communities[currentCommunitySlug].channels[channelName] = channel
						}
						resolve(this._communities[currentCommunitySlug].channels)
					})
					.catch(error => {
						console.error(error)
						reject()
					})
					.finally(() => {
						this._current_community_load_status.channels = true
						if (this._current_community_load_status.dms) {
							// TODO: Bring back the below lines once we are ready
							// this.getCommunityActivity()
							// this.triggerCommunityActivityPolling()
						}
					})
			})
		},
		getDMChannels() {
			return new Promise((resolve, reject) => {
				if (!this._current_community_slug) reject('No Community Found')
				axios
					.get(`v1/communities/${this._current_community_slug}/dm`)
					.then(response => {
						const channels = response.data.channels
						if (!this._communities[this._current_community_slug].channels) {
							this._communities[this._current_community_slug].channels = {}
						}

						for (const channel_name in channels) {
							const channel = channels[channel_name]
							this._communities[this._current_community_slug].channels[channel_name] = standardizeDMChannel(
								channel,
								this._self,
								this._communities[this._current_community_slug].members
							)
							let threadLoadingCounter = 0
							for (const messageId in channel.messages) {
								const message = channel.messages[messageId]
								if (message?.thread_count > 0) {
									setTimeout(() => this.getThreadMessages({}, message.message_id, channel.name), threadLoadingCounter * 100)
									threadLoadingCounter++
								}
							}
						}
						resolve(this._communities[this._current_community_slug].channels)
					})
					.catch(error => {
						console.error(error)
						reject()
					})
					.finally(() => {
						this._current_community_load_status.dms = true
						if (this._current_community_load_status.channels) {
							this.getCommunityActivity()
							this.triggerCommunityActivityPolling()
						}
					})
			})
		},
		getChannel(channelName) {
			return new Promise((resolve, reject) => {
				if (!this._current_community_slug) reject('No Community Found')
				if (!channelName) reject('Need Channel Name')
				axios
					.get(`v1/communities/${this._current_community_slug}/channels/${channelName}`)
					.then(response => {
						if (!this._communities[this._current_community_slug].channels) {
							this._communities[this._current_community_slug].channels = {}
						}
						let threadLoadingCounter = 0
						const channel = response.data.channel
						channel.threads = {}
						this._communities[this._current_community_slug].channels[channelName] = channel
						for (const messageId in channel.messages) {
							const message = channel.messages[messageId]
							if (message?.thread_count > 0) {
								setTimeout(() => this.getThreadMessages({}, message.message_id, channel.name), threadLoadingCounter * 100)
								threadLoadingCounter++
							}
						}
						resolve(this._communities[this._current_community_slug].channels[channelName])
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		async getCreateDM(input) {
			const members = Array.isArray(input) ? input : [input]
			const memberIds = members.map(member => member.community_member_id)
			return new Promise((resolve, reject) => {
				axios
					.post(`v1/communities/${this._current_community_slug}/dm`, {
						member_ids: memberIds,
					})
					.then(response => {
						const channel = response.data.channel

						const exists = Object.values(this._communities[this._current_community_slug].channels).find(
							ch => ch.channel_id === channel.channel_id
						)
						if (!exists) {
							const channel_name = channel.name
							this._communities[this._current_community_slug].channels[channel_name] = standardizeDMChannel(
								channel,
								this._self,
								this.members
							)
						}
						resolve(channel)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		async setCurrentChannel(channelName) {
			const currChannel = this.channels[channelName]
			if (currChannel) {
				this._current_channel_name = channelName
				this.markChannelAsRead()
				const msgs = this.getMessages({ mark_read: 1 }, 'append')
				const pinnedMsgs = this.getMessages({ status: constants.MESSAGE_STATUS.PINNED, mark_read: 1, page_size: 100 }, 'append')
				await Promise.allSettled([msgs, pinnedMsgs])
				this._current_community_load_inital_messages_status = true
				this.preloadTagMessages([null]) //Skip all messages since it is already loaded
			} else {
				this._current_channel_name = null
				this._messages = {}
			}
			this.triggerChannelPolling()
		},
		async bulkMarkMessagesAsRead(messageIds) {
			if (messageIds.length) {
				await axios.post(`v1/communities/${this._current_community_slug}/messages/bulk/read`, {
					message_ids: messageIds,
				})
			}
		},
		markChannelAsRead() {
			// if (this._channels_unread && this._channels_unread[this._current_channel_name])
			//     delete this._channels_unread[this._current_channel_name]
			if (this.currentChannel.community_id === this.currentCommunity.community_id)
				axios.post(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/read`)
		},
		async getMessageDetails(communitySlug, channelName, messageId) {
			const response = await axios.get(`v1/communities/${communitySlug}/channels/${channelName}/messages/${messageId}`)
			return response.data.message
		},
		setTag(tagId) {
			if (this._current_tag_id != tagId) {
				// null is the `all` tag. If no tag is provided we default to all
				this._current_tag_id = tagId ? tagId : null
				// If the selected tag has no messages fetch messages for the tag, in-case it is just not pre-loaded
				if (
					!this._communities[this._current_community_slug].tagMessageIds[this._current_tag_id] ||
					this._communities[this._current_community_slug].tagMessageIds[this._current_tag_id].length == 0
				) {
					this.getMessages({}, 'append')
				}
			}
		},
		createTag(name) {
			return new Promise((resolve, reject) => {
				axios
					.post(`v1/communities/${this._current_community_slug}/tags`, {
						name: name,
					})
					.then(response => {
						this._communities[this._current_community_slug].tags[response.data.tag.tag_id] = response.data.tag
						this._communities[this._current_community_slug].tagMessageIds[response.data.tag.tag_id] = []
						resolve(response.data.tag)
					})
					.catch(error => {
						this._isChannelMessagePolling = false
						if (error.response?.status >= 400 && error.response?.status < 600) clearInterval(this._channel_polling_interval)
						console.error(error)
						reject()
					})
			})
		},
		deleteTag(tagId) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`v1/communities/${this._current_community_slug}/tags/${tagId}`)
					.then(() => {
						delete this._communities[this._current_community_slug].tags[tagId]
						this._communities[this._current_community_slug].tagMessageIds[tagId] = []
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		preloadTagMessages(skip = []) {
			// Attempt to preload all tags
			// null is the "all" tag
			const tagIds = [null, ...Object.keys(this.tags)]
			for (let tagId of tagIds) {
				if (skip.includes(tagId)) {
					continue
				}

				if (
					!this._communities[this._current_community_slug].tagMessageIds[tagId] ||
					this._communities[this._current_community_slug].tagMessageIds[tagId].length == 0
				) {
					this.getMessages({ status: constants.MESSAGE_STATUS.PINNED, mark_read: 1, page_size: 100 }, 'append', tagId)
					this.getMessages({ mark_read: 1 }, 'append', tagId)
				}
			}
		},
		updateTagName(tagId, updatedName) {
			return new Promise((resolve, reject) => {
				axios
					.patch(`v1/communities/${this._current_community_slug}/tags/${tagId}`, {
						name: updatedName,
					})
					.then(() => {
						this._communities[this._current_community_slug].tags[tagId].name = updatedName
						resolve()
					})
					.catch(error => {
						console.error(error)
						reject()
					})
			})
		},
		getMessages(params, action = 'replace', tagId = this._current_tag_id) {
			return new Promise((resolve, reject) => {
				this._isChannelMessagePolling = true
				if (!this._current_community_slug) {
					this._isChannelMessagePolling = false
					reject('No Community Found')
				} else if (!this._current_channel_name) {
					this._isChannelMessagePolling = false
					reject('No Channel Found')
				}
				const currentCommunitySlug = `${this._current_community_slug}`
				let currentChannelName = `${this._current_channel_name}`

				if (!currentChannelName) {
					this.triggerChannelPolling()
					return
				}
				let url = `v1/communities/${currentCommunitySlug}/channels/${currentChannelName}/messages`
				axios
					.get(url, {
						params: {
							...params,
							tag_id: tagId,
						},
					})
					.then(response => {
						if (this._sendingMessage) {
							this._isChannelMessagePolling = false
							return resolve(this._messages)
						}
						const messages = response.data.messages
						if (Object.keys(messages).length) currentChannelName = this.channelIdToNameMap[Object.values(messages)[0].channel_id]

						if (action == 'append') {
							// tagMessageIds retains the messages to show for a specfic tag as-well as the order
							this._communities[currentCommunitySlug].tagMessageIds[tagId] =
								this._communities[currentCommunitySlug].tagMessageIds[tagId] || []
							for (const message_id in messages) {
								this._communities[currentCommunitySlug].tagMessageIds[tagId].push(message_id)
								const message = messages[message_id]
								updateMessageDataInState(message, this)
							}
						} else {
							this._communities[currentCommunitySlug].channels[currentChannelName].messages = messages
							this._communities[currentCommunitySlug].tagMessageIds = {}
							this._communities[currentCommunitySlug].tagMessageIds[tagId] = []
							for (const message_id in messages) {
								this._communities[currentCommunitySlug].tagMessageIds[tagId].push(message_id)
							}
						}

						this._isChannelMessagePolling = false
						resolve(this._messages)
					})
					.catch(error => {
						this._isChannelMessagePolling = false
						if (error.response?.status >= 400 && error.response?.status < 600) clearInterval(this._channel_polling_interval)
						console.error(error)
						reject()
					})
			})
		},
		upsertMessage(message) {
			updateMessageDataInState(message, this)
		},
		getNotifications() {
			return new Promise((resolve, reject) => {
				if (!this._current_community_slug) {
					reject('No Community Found')
				}
				axios
					.get(`/v1/communities/${this._current_community_slug}/notifications`)
					.then(response => {
						this._communities[this._current_community_slug].notifications = response.data.notifications
					})
					.catch(error => {
						console.error('Error fetching notifications:', error)
						reject(error)
					})
			})
		},
		updateNotificationStatus(messageNotificationId, status) {
			return new Promise((resolve, reject) => {
				this._sendingMessage = true
				const payload = { status }
				axios
					.patch(`v1/communities/${this._current_community_slug}/notifications/${messageNotificationId}/status`, payload)
					.then(response => {
						this._communities[this._current_community_slug].notifications = response.data.notifications
					})
					.catch(err => {
						console.error(err)
						reject(err)
					})
					.finally(() => {
						this._sendingMessage = false
					})
			})
		},
		clearAllNotificationStatus() {
			return new Promise((resolve, reject) => {
				this._sendingMessage = true
				this._communities[this._current_community_slug].notifications = {}
				axios
					.patch(`v1/communities/${this._current_community_slug}/notifications/clear`)
					.then(response => {
						this._communities[this._current_community_slug].notifications = response.data.notifications
						resolve(response)
					})
					.catch(err => {
						console.error(err)
						reject(err)
					})
					.finally(() => {
						this._sendingMessage = false
					})
			})
		},
		getChannelUnreadStatuses() {
			this._isChannelsUnReadPolling = true
			axios
				.get(`v1/communities/${this._current_community_slug}/channels/unread`)
				.then(response => {
					this._channels_unread = response.data.channels_unread_statuses
					Object.keys(this._channels_unread).forEach(channelName => {
						if (this.hasCommunityLoaded && !(this.channels[channelName] && this.channels[channelName].name)) {
							this.getChannel(channelName)
						}
					})
				})
				.catch(error => {
					if (error.response?.status >= 400 && error.response?.status < 600) {
						clearInterval(this._channels_unread_polling_interval)
					}
					console.error(error)
				})
				.finally(() => {
					this._isChannelsUnReadPolling = false
					this._current_community_load_status.unread_status = true
				})
		},
		triggerChannelUnreadStatusPolling() {
			if (POLLING_DISABLED) {
				return
			}
			if (this._channels_unread_polling_interval) {
				clearInterval(this._channels_unread_polling_interval)
			}

			this._channels_unread_polling_interval = setInterval(() => {
				if (!this._current_community_slug) {
					clearInterval(this._channels_unread_polling_interval)
				}

				if (!this._isMessagesLaterPolling) {
					this.getChannelUnreadStatuses()
				}
			}, 5000)
		},
		triggerChannelPolling() {
			if (POLLING_DISABLED) {
				return
			}
			if (this._channel_polling_interval) clearInterval(this._channel_polling_interval)
			if (!this._current_channel_name) return
			setTimeout(() => {
				this._channel_polling_interval = setInterval(() => {
					if (!this._current_channel_name) clearInterval(this._channel_polling_interval)

					if (!this._isChannelMessagePolling) {
						const messageIdentifiers = Object.keys(this.messages)
						const oldestMessageId = messageIdentifiers.length
							? this.tagMessages[messageIdentifiers.map(messageId => parseInt(messageId)).sort((a, b) => a - b)[0]].message_id
							: null
						this.getMessages(
							{
								mark_read: 1,
								minimum_message_id: oldestMessageId - 1,
							},
							'append'
						)
					}
				}, 60000)
			}, 1000)
		},
		removeChannelParticipant(memberId) {
			return new Promise((resolve, reject) => {
				axios
					.delete(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/participant/${memberId}`, {})
					.then(() => {
						const participantIndex = this._communities[this._current_community_slug].channels[
							this._current_channel_name
						].participants.findIndex(participant => participant.community_member_id === memberId)
						if (participantIndex > -1) {
							this._communities[this._current_community_slug].channels[this._current_channel_name].participants.splice(
								participantIndex,
								1
							)
						}
						resolve()
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		addChannelParticipant(memberId) {
			return new Promise((resolve, reject) => {
				axios
					.post(`v1/communities/${this._current_community_slug}/channels/${this._current_channel_name}/participant/${memberId}`, {})
					.then(response => {
						const participant = response.data.participant
						addChannelParticipantInState(participant, this)
						resolve(participant)
					})
					.catch(error => {
						reject(error)
					})
			})
		},
		sendMessage(content, tag_id, parent_message_id = null) {
			const channelId = this.currentChannel.channel_id
			const channelName = this._current_channel_name
			return new Promise((resolve, reject) => {
				this._sendingMessage = true
				const utcTimestampInMilliseconds = DateTime.utc().ts
				const payload = {
					content: content.content,
					member: this.self.community_member_id,
					digital_assets: [...content.files, ...content.audio],
					link_previews: content.linkPreviews,
					mentions: content.mentions,
					tag_id,
					notify: content.notify,
					parent_message_id,
					platform: 'web',
					type: content.type,
					summary: content.summary,
					title: content.title,
				}
				const messageToAdd = {
					message_id: null,
					sender_id: this.self.community_member_id,
					channel_id: channelId,
					parent_message_id,
					content: content.content,
					timestamp_in_milli: utcTimestampInMilliseconds,
					timestamp: utcTimestampInMilliseconds / 1000,
					data: {
						digital_assets: [...content.files, ...content.audio],
						link_previews: content.linkPreviews,
					},
					thread_count: 0,
					status: constants.MESSAGE_STATUS.ACTIVE,
				}
				if (parent_message_id) {
					this._skipMessageUpdate[parent_message_id] = Math.min((this._skipMessageUpdate[parent_message_id] ?? 0) + 1, 3)
					const messages = { ...(this._current_channel_name[parent_message_id]?.messages || {}) }
					messages[utcTimestampInMilliseconds] = messageToAdd
					if (!this._communities[this._current_community_slug].channels[channelName].threads[parent_message_id]) {
						this._communities[this._current_community_slug].channels[channelName].threads[parent_message_id] = { messages: {} }
					}
					if (!this._communities[this._current_community_slug].channels[channelName].threads[parent_message_id]?.messages)
						this._communities[this._current_community_slug].channels[channelName].threads[parent_message_id].messages = messages
					messages[utcTimestampInMilliseconds] = messageToAdd

					if (this._communities[this._current_community_slug].channels[channelName].messages[parent_message_id]) {
						this._communities[this._current_community_slug].channels[channelName].messages[parent_message_id].thread_count++
					}
				} else {
					const messages = { ...this.messages }
					const tmpMessage = { ...messageToAdd, message_id: utcTimestampInMilliseconds }
					messages[utcTimestampInMilliseconds] = tmpMessage
					this._communities[this._current_community_slug].channels[channelName].messages = messages
					addMessageToTagMessageIds(tmpMessage, this)
				}

				axios
					.post(`v1/communities/${this._current_community_slug}/channels/${channelName}/messages`, payload)
					.then(response => {
						const message = response.data.message
						const channelName = this.channelIdToNameMap[message.channel_id]
						if (parent_message_id) {
							const messages = { ...(this.getChannelThread(channelName)[parent_message_id]?.messages || {}) }
							delete messages[utcTimestampInMilliseconds]
							messages[message.message_id] = message
							this._communities[this._current_community_slug].channels[channelName].threads[parent_message_id].messages = messages
						} else {
							const messages = { ...this.messages }
							delete messages[utcTimestampInMilliseconds]
							messages[message.message_id] = message
							this._communities[this._current_community_slug].channels[channelName].messages = messages
							// No need to remove from tagMessageIds as it will skip over the missing data
							addMessageToTagMessageIds(message, this)
						}

						resolve(message)
					})
					.catch(err => {
						console.error(err)
						reject(err)
					})
					.finally(() => {
						this._sendingMessage = false
					})
			})
		},
		editMessage(content, message) {
			return new Promise((resolve, reject) => {
				this._sendingMessage = true
				const messageId = message.message_id
				const parentMessageId = message.parent_message_id
				const payload = {
					content: content.content,
					member: this.self.community_member_id,
					message_id: messageId,
					parent_message_id: parentMessageId,
					mentions: content.mentions,
				}
				const channelName = this.channelIdToNameMap[message.channel_id]
				if (this._messages_later.results[messageId]) this._messages_later.results[messageId].content = content
				if (parentMessageId) {
					this._communities[this._current_community_slug].channels[channelName].threads[parentMessageId].messages[messageId].content =
						content.content
					this._communities[this._current_community_slug].channels[channelName].threads[parentMessageId].messages[messageId].edited = true
				} else {
					this._communities[this._current_community_slug].channels[channelName].messages[messageId].content = content.content
					this._communities[this._current_community_slug].channels[channelName].messages[messageId].edited = true
				}
				axios
					.patch(`v1/communities/${this._current_community_slug}/channels/${channelName}/messages`, payload)
					.then(response => {
						if (this._messages_later.results[messageId]) this._messages_later.results[messageId] = response.data.message
						resolve(response)
					})
					.catch(err => {
						console.error(err)
						reject(err)
					})
					.finally(() => {
						this._sendingMessage = false
					})
			})
		},
		updateMessageStatus(message, status) {
			return new Promise((resolve, reject) => {
				this._sendingMessage = true
				const messageId = message.message_id
				const channelName = this.channelIdToNameMap[message.channel_id]
				const payload = {
					status,
				}
				axios
					.patch(`v1/communities/${this._current_community_slug}/channels/${channelName}/messages/${messageId}/status`, payload)
					.then(response => {
						this._communities[this._current_community_slug].channels[channelName].messages[messageId].status =
							response.data.message.status
						resolve(response)
					})
					.catch(err => {
						console.error(err)
						reject(err)
					})
					.finally(() => {
						this._sendingMessage = false
					})
			})
		},
		deleteMessage(message) {
			return new Promise((resolve, reject) => {
				this._sendingMessage = true
				const messageId = message.message_id
				const channelName = this.channelIdToNameMap[message.channel_id]
				deleteMessageInState(message, this)
				axios
					.delete(`v1/communities/${this._current_community_slug}/channels/${channelName}/messages/${messageId}`)
					.then(response => {
						resolve(response)
					})
					.catch(err => {
						console.error(err)
						reject(err)
					})
					.finally(() => {
						this._sendingMessage = false
					})
			})
		},
		async reacted(message, reaction) {
			this._sendingMessage = true
			const messageId = message.message_id
			const selfId = this._self.community_member_id
			const channelName = this.channelIdToNameMap[message.channel_id]
			const originalMessage = JSON.parse(JSON.stringify(message))

			reaction.member_id = selfId.toString()
			const action = reactedInState(message, reaction, this)

			try {
				const payload = {
					action,
					member: this._self.community_member_id,
					name: reaction.name,
					unicode: reaction.unicode,
				}
				const message = await axios.post(
					`v1/communities/${this._current_community_slug}/channels/${channelName}/messages/${messageId}/react`,
					payload
				)
				this._sendingMessage = false
				return action
			} catch (err) {
				this._sendingMessage = false
				console.error(err)
				return action
			}
		},
		async setCurrentThread(threadId) {
			return new Promise(async (resolve, reject) => {
				if (threadId) {
					const currentThreadMessage = this.messages[threadId]
					if (!currentThreadMessage) {
						try {
							const message = await this.getMessageDetails(this._current_community_slug, this._current_channel_name, threadId)
							this.messages[threadId] = message
						} catch (error) {
							this._current_thread_id = null
							reject(error)
						}
					}
					if (currentThreadMessage) {
						this._current_thread_id = parseInt(threadId, 10)
						if (!Object.keys(this.threadMessages).length) {
							this._is_current_thread_loading = true
						}
						this.getThreadMessages({ mark_read: 1 })
						this.triggerThreadPolling()
						resolve(this.communities)
					} else {
						this._current_thread_id = null
						reject("Thread Parent couldn't be found!")
					}
				} else {
					this._current_thread_id = null
					resolve()
				}
			})
		},
		getThreadMessages(params = {}, parentMessageId = undefined, channelName = undefined) {
			return new Promise((resolve, reject) => {
				if (!!parentMessageId && parentMessageId === this._current_thread_id) this._isThreadMessagePolling = true
				if (!this._current_community_slug) {
					this._isThreadMessagePolling = false
					reject('No Community Found')
				}

				if (!parentMessageId) {
					parentMessageId = this._current_thread_id
				}
				params.parent_message_id = parentMessageId

				if (!parentMessageId) return

				const currentCommunitySlug = `${this._current_community_slug}`
				let currentChannelName = `${this._current_channel_name}`

				if (channelName) {
					currentChannelName = channelName
				}

				if (!this._communities[currentCommunitySlug].channels[currentChannelName]?.messages[parentMessageId])
					this.getMessageDetails(currentCommunitySlug, currentChannelName, parentMessageId).then(message => {
						updateMessageDataInState(message, this)
					})

				axios
					.get(`v1/communities/${currentCommunitySlug}/channels/${currentChannelName}/messages`, {
						params,
					})
					.then(response => {
						if (this._sendingMessage) {
							this._isThreadMessagePolling = false
							resolve(this.threadMessages)
						}
						if (!this._communities[currentCommunitySlug].channels[currentChannelName].threads[parentMessageId]?.messages) {
							this._communities[currentCommunitySlug].channels[currentChannelName].threads[parentMessageId] = { messages: {} }
						}

						if (parentMessageId === this._current_thread_id) {
							this._is_current_thread_loading = false
						}
						for (const messageId in response.data.messages) {
							const message = response.data.messages[messageId]

							if ((this._skipMessageUpdate[message.message_id] ?? 0) > 0) {
								this._skipMessageUpdate[message.message_id] -= 1
							} else {
								this._communities[currentCommunitySlug].channels[currentChannelName].threads[parentMessageId].messages[messageId] =
									message
							}
						}
						this._isThreadMessagePolling = false
						resolve(this._communities[currentCommunitySlug].channels[currentChannelName].threads[parentMessageId])
					})
					.catch(error => {
						this._isThreadMessagePolling = false
						console.error(error)
						reject()
					})
			})
		},
		triggerThreadPolling() {
			if (POLLING_DISABLED) {
				return
			}
			if (this._thread_polling_interval) clearInterval(this._thread_polling_interval)
			if (this._current_thread_id)
				this._thread_polling_interval = setInterval(() => {
					if (!this._isThreadMessagePolling) {
						const messageIdentifier = Object.keys(this.threadMessages)

						const oldestMessageId = messageIdentifier.length
							? this.threadMessages[messageIdentifier.map(messageId => parseInt(messageId, 10)).sort((a, b) => a - b)[0]].message_id
							: null
						this.getThreadMessages(
							{
								mark_read: 1,
								minimum_message_id: oldestMessageId - 1,
							},
							undefined
						)
					}
				}, 5000)
		},
		async inviteEditUser(payload) {
			const response = await axios.post(`v1/communities/${this._current_community_slug}/invite`, payload)
			return response.message
		},
		async inviteNewMember(payload) {
			const response = await axios.post(`v1/communities/${this._current_community_slug}/access/invite`, payload)
			this.communities[this._current_community_slug].members[response.data.member.community_member_id] = response.data?.member
			return response.message
		},
		async updateCommunityData(payload) {
			// Splice into settings
			if (this.currentCommunity?.data) {
				Object.assign(this.currentCommunity.data, payload)
			}
			if (payload.theme !== undefined) {
				const appEl = document.getElementById('app')
				appEl.className = ''
				appEl.classList.add(`theme-${payload.theme}`)
			}
			const response = await axios.put(`v1/communities/${this._current_community_slug}`, payload)

			return response.message
		},

		async changeMemberType(communityMemberId, memberType) {
			await axios.put(`v1/communities/${this._current_community_slug}/members/${communityMemberId}/type`, { type: memberType })
			this.getMembers()
			this.getSelf()
		},
		logout() {
			clearInterval(this._channels_unread_polling_interval)
			clearInterval(this._messages_later_polling_interval)
			clearInterval(this._channel_polling_interval)
			clearInterval(this._thread_polling_interval)
			clearInterval(this._activity_polling_interval)
			this.$sse.off('message', handleStreamEvents)
			this.$reset()
		},
		getMessagesLaterStatus() {
			this._isMessagesLaterPolling = true
			axios
				.get(`v1/communities/${this._current_community_slug}/messages/later`)
				.then(response => {
					const finalLaterObject = { count: 0, results: {} }
					const messagesMarkedForLater = response.data.results
					// Existing items that have been marked for later recently are added back in as
					// it is possible the server doesn't have that copy yet
					Object.keys(this._messages_later.results).forEach(messageId => {
						if ((this._skipMessageLaterUpdate[messageId] ?? 0) > 0) {
							finalLaterObject.results[messageId] = messagesMarkedForLater[messageId]
							finalLaterObject.count++
						}
					})

					// Server items should mainly only have things the user hasn't touched recently.
					// If they have then we ignore for now, even if it means that we are missing that info for a few seconds
					Object.keys(messagesMarkedForLater).forEach(messageId => {
						if ((this._skipMessageLaterUpdate[messageId] ?? 0) > 0) {
							this._skipMessageLaterUpdate[messageId] -= 1
						} else {
							finalLaterObject.results[messageId] = messagesMarkedForLater[messageId]
							finalLaterObject.count++
						}
					})
					this._messages_later = finalLaterObject
				})
				.catch(error => {
					if (error.response?.status >= 400 && error.response?.status < 600) {
						clearInterval(this._messages_later_polling_interval)
					}
					console.error(error)
				})
				.finally(() => {
					this._isMessagesLaterPolling = false
				})
		},
		triggerMessageLaterPolling() {
			if (POLLING_DISABLED) {
				return
			}
			if (this._messages_later_polling_interval) {
				clearInterval(this._messages_later_polling_interval)
			}

			this._messages_later_polling_interval = setInterval(() => {
				if (!this._current_community_slug) {
					clearInterval(this._messages_later_polling_interval)
				}

				if (!this._isMessagesLaterPolling) {
					this.getMessagesLaterStatus()
				}
			}, 5000)
		},
		async unMarkMessageForLater(message) {
			this._skipMessageLaterUpdate[message.message_id] = Math.min((this._skipMessageLaterUpdate[message.message_id] ?? 0) + 1, 3)
			const channelName = this.channelIdToNameMap[message.channel_id]
			const messagesForLaterInChannel = this._messages_later.results
			delete messagesForLaterInChannel[message.message_id]
			this._messages_later.count--
			this._messages_later.results = { ...messagesForLaterInChannel }
			await axios.delete(`v1/communities/${this._current_community_slug}/channels/${channelName}/messages/${message.message_id}/later`)
		},
		async markMessageForLater(message) {
			this._skipMessageLaterUpdate[message.message_id] = Math.min((this._skipMessageLaterUpdate[message.message_id] ?? 0) + 1, 3)
			const channelName = this.channelIdToNameMap[message.channel_id]
			const messagesForLaterInChannel = { ...this._messages_later.results }
			messagesForLaterInChannel[message.message_id] = message
			this._messages_later.results = messagesForLaterInChannel
			this._messages_later.count++

			await axios.post(`v1/communities/${this._current_community_slug}/channels/${channelName}/messages/${message.message_id}/later`)
		},
		markActivitySeen() {
			this._is_activity_unread = false
		},
		getCommunityActivity(maxMessageId = null, minMessageId = null) {
			const params = {}
			if (maxMessageId) params.maximum_message_id = maxMessageId
			if (minMessageId) params.minimum_message_id = minMessageId
			this._isActivityPolling = true
			axios
				.get(`v1/communities/${this._current_community_slug}/activity`, { params })
				.then(response => {
					const messages = response.data.messages
					let isActivityUpdated = false
					const oldActivityMessageIds = Object.keys(this._communities[this._current_community_slug].activity)
					const newActivityMessageIds = Object.keys(messages)
					isActivityUpdated = oldActivityMessageIds.length > 0 && oldActivityMessageIds.length !== newActivityMessageIds.length
					let threadLoadingCounter = 0
					newActivityMessageIds.forEach(messageId => {
						const message = messages[messageId]
						const channelName = this.channelIdToNameMap[message.channel_id]

						if (message.type === 'mention') {
							const messageExists = message.parent_message_id
								? !!this._communities[this._current_community_slug].channels[channelName]?.threads[message.parent_message_id]
										?.messages[messageId]
								: this._communities[this._current_community_slug].channels[channelName].messages[messageId]
							if (!messageExists) {
								if (message.parent_message_id) {
									setTimeout(() => this.getThreadMessages({}, message.parent_message_id, channelName), threadLoadingCounter * 100)
									threadLoadingCounter++
								} else {
									this.getMessageDetails(this._current_community_slug, channelName, messageId).then(message => {
										updateMessageDataInState(message, this)
									})
								}
								isActivityUpdated = true
							}
						} else if (message.type === 'thread') {
							setTimeout(() => this.getThreadMessages({}, messageId, channelName), threadLoadingCounter * 100)
							threadLoadingCounter++
						}
					})
					if (isActivityUpdated) this._is_activity_unread = true
					this._communities[this._current_community_slug].activity = {
						...this._communities[this._current_community_slug].activity,
						...messages,
					}
				})

				.catch(error => {
					console.error(error)
				})
				.finally(() => {
					this._isActivityPolling = false
				})
		},
		triggerCommunityActivityPolling() {
			if (POLLING_DISABLED) {
				return
			}
			if (this._activity_polling_interval) clearInterval(this._activity_polling_interval)
			if (!this._current_community_slug) return
			setTimeout(() => {
				this._activity_polling_interval = setInterval(() => {
					if (!this._current_community_slug) clearInterval(this._activity_polling_interval)

					if (!this._isActivityPolling) {
						const activity = this._communities[this._current_community_slug].activity
						const messageIdentifiers = Object.keys(activity)
						const oldestMessageId = messageIdentifiers.length
							? activity[messageIdentifiers.map(messageId => parseInt(messageId, 10)).sort((a, b) => a - b)[0]].message_id
							: null
						this.getCommunityActivity(null, oldestMessageId)
					}
				}, 60000)
			}, 1000)
		},
		async toggleChannelVisibility(channel) {
			const hiddenChannels = this._self.data.hidden_channels || []
			const hiddenChannelIndex = hiddenChannels.indexOf(channel.channel_id)
			if (hiddenChannelIndex > -1) hiddenChannels.splice(hiddenChannelIndex, 1)
			else hiddenChannels.push(channel.channel_id)
			await axios.put(`v1/communities/${this._current_community_slug}/self`, {
				hidden_channels: hiddenChannels,
			})
			this.getSelf()
		},
		/** Deprecated @ 29-11-2023.
		 * TODO: Delete by 29-02-2024
		 */
		getMessagesMentionedIn(maxMessageId = null) {
			const params = maxMessageId
				? {
						maximum_message_id: maxMessageId,
					}
				: {}
			axios
				.get(`v1/communities/${this._current_community_slug}/mentions`, { params })
				.then(response => {
					const messages = response.data.messages
					Object.keys(messages).forEach(messageId => {
						const message = messages[messageId]
						const channelName = this.channelIdToNameMap[message.channel_id]

						const messageExists = message.parent_message_id
							? !!this._communities[this._current_community_slug].channels[channelName]?.threads[message.parent_message_id]?.messages[
									messageId
								]
							: this._communities[this._current_community_slug].channels[channelName].messages[messageId]
						if (!messageExists)
							if (message.parent_message_id) {
								this.getThreadMessages({}, message.parent_message_id, channelName)
							} else {
								this.getMessageDetails(this._current_community_slug, channelName, messageId).then(message => {
									updateMessageDataInState(message, this)
								})
							}
					})
					this._mentioned_message_ids = { ...this._mentioned_message_ids, ...messages }
				})

				.catch(error => {
					console.error(error)
				})
		},
		// Can use singular memberID
		getMemberDetails(memberIdOrIds) {
			const membersData = []
			if (typeof memberIdOrIds === 'number') {
				const memberData = {
					...this.currentCommunity.members[memberIdOrIds]?.data,
					status: this.currentCommunity.members[memberIdOrIds]?.status,
				}
				this.currentCommunity.members[memberIdOrIds] && membersData.push(memberData)
			} else if (Array.isArray(memberIdOrIds)) {
				for (const memberId of memberIdOrIds) {
					const memberData = { ...this.currentCommunity.members[memberId]?.data, status: this.currentCommunity.members[memberId]?.status }
					this.currentCommunity.members[memberId] && membersData.push(memberData)
				}
			}
			return membersData
		},
	},
})
