<template>
	<AppModal
		ref="deleteMemberModal"
		title="Are you sure?"
		:subheading="`Are you sure you want to remove ${member?.data?.full_name} from this community?`"
		:loading="loading"
		@close="close"
		class="delete-member-modal"
		:modalStyles="{ 'min-width': '500px' }"
	>
		<AppButton class="mt-4" @click="deleteMember" :loading="loading">Remove Member</AppButton>
		<AppButton outline class="mt-4 !border-none !text-stan-gray-dark" @click="close">Never Mind</AppButton>
	</AppModal>
</template>

<script setup>
	import { ref, computed } from 'vue'
	import { notify } from '@kyvg/vue3-notification'
	import { useCommunityStore } from '@/stores/communities'

	const props = defineProps({
		member: Object,
	})

	const loading = ref(false)
	const deleteMemberModal = ref(null)
	const communityStore = computed(() => useCommunityStore())

	const emit = defineEmits('user_removed')

	const show = () => {
		deleteMemberModal.value.show()
	}
	const close = () => {
		deleteMemberModal.value.hide()
	}

	const deleteMember = async () => {
		try {
			loading.value = true
			await communityStore.value.removeCommunityMember(props.member.id)
			notify({
				type: 'Success',
				title: `Done!`,
				text: `Member Removed.`,
			})
		} catch (error) {
			notify({ type: 'error', text: 'Something went wrong when removing your member, please try again.' })
		}
		close()
		loading.value = false
	}

	defineExpose({
		show,
	})
</script>

<style lang="scss" scoped>
	.delete-member-modal {
		:deep(.header) {
			@apply mb-0;
		}
		:deep(.app-card-container) {
			overflow: unset;
		}
	}
</style>
