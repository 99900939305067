<template>
	<AppModal
		ref="addCategoryModal"
		title=" "
		action="Add Category"
		:loading="loading"
		:backdrop="backdrop"
		@cta="addCategory"
		@close="close"
		:modalStyles="{ 'min-width': '500px' }"
	>
		<AppInput
			class="mb-2"
			v-model="title"
			label="Name Category"
			placeholder="Name your category here"
			:error="!loading && v$.title.$errors"
			:maxlength="20"
		/>
	</AppModal>
</template>

<script>
	import { useVuelidate } from '@vuelidate/core'
	import { required, helpers } from '@vuelidate/validators'
	import { useCommunityStore } from '@/stores/communities'

	export default {
		setup: () => ({ v$: useVuelidate() }),
		props: {
			isEditing: {
				type: Boolean,
				default: false,
			},
			backdrop: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				title: null,
				loading: false,
			}
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			showing() {
				return this.$refs.addCategoryModal.showing
			},
			selfId() {
				return this.communityStore.self.community_member_id
			},
			channels() {
				return this.communityStore.channels
			},
			tags() {
				return this.communityStore.tags
			},
			tagNames() {
				return Object.keys(this.tags).map(key => this.tags[key].name.toUpperCase())
			},
		},
		validations() {
			return {
				title: {
					required: helpers.withMessage('Title is required', required),
					unique: helpers.withMessage('Category already exists', value => !this.tagNames.includes(value.toUpperCase())),
				},
			}
		},
		methods: {
			show() {
				this.v$.$reset()
				this.$refs.addCategoryModal.show()
				this.resetForm()
			},
			close() {
				this.$emit('close')
				this.$refs.addCategoryModal.hide()
			},
			resetForm() {
				this.title = null
			},
			productTagsUpdated(tags) {
				this.selectedProducts = tags.map(e => e.page_id)
			},
			async addCategory() {
				const isFormCorrect = await this.v$.$validate()
				if (!isFormCorrect || Object.keys(this.channels).includes(this.title)) {
					return
				}
				this.loading = true
				this.$stanAnalytics('community-category-create')
				const tag = await this.communityStore.createTag(this.title)
				this.$notify({
					type: 'Success',
					title: '',
					text: 'New Category was successfully created.',
				})
				tag && this.$emit('added-tag', tag)
				this.close()
				this.loading = false
			},
		},
	}
</script>

<style lang="scss" scoped>
	.invite-user-modal {
		:deep(.modal-inner-title) {
			overflow: visible;
		}
	}
</style>
