<template>
	<div class="backdrop d-flex">
		<router-view />
	</div>
</template>

<script>
	import { useGlobalsStore } from '@/stores/globals'

	export default {
		name: 'AuthContainer',
		created() {
			this.globalStore.setPageLoading(false)
			this.$themeHelper.addThemeColorsToDocument()
		},
		computed: {
			globalStore() {
				return useGlobalsStore()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.backdrop {
		height: 100vh;
		min-width: 100vw;
		overflow: auto;
	}
</style>
