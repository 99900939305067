<template>
	<transition name="fade" v-if="hasToolbar">
		<div id="toolbar-container" key="toolbar" :class="toolbarClass">
			<button
				class="ql-bold"
				:class="{ 'ql-active': activeToolbarBtns.includes('bold') }"
				@click.prevent="[toggleBold(), toggleToolbarBtn('bold')]"
			>
				<AppIcon src="bold" />
			</button>
			<button
				class="ql-italic"
				:class="{ 'ql-active': activeToolbarBtns.includes('italic') }"
				@click.prevent="[toggleItalic(), toggleToolbarBtn('italic')]"
			>
				<AppIcon src="italic" />
			</button>
			<button
				class="ql-list"
				:class="{ 'ql-active': activeToolbarBtns.includes('list') }"
				value="bullet"
				@click.prevent="[toggleBulletList(), toggleToolbarBtn('list')]"
			>
				<AppIcon src="bullet-list" />
			</button>
			<button @click.prevent="$emit('pick-file')" v-if="shouldShowImagePicker">
				<AppIcon src="image" />
			</button>
			<button @click.prevent="setVideo()" v-if="shouldShowVideoPicker">
				<AppIcon src="video" :style="{ width: '18px' }" />
			</button>
			<button class="ql-link" :class="{ 'ql-active': activeToolbarBtns.includes('link') }" @click.prevent="setLink()" v-if="shouldShowLink">
				<AppIcon src="buckle" />
			</button>
			<button @click.prevent="$emit('pick-file')" v-if="shouldShowFilePicker">
				<AppIcon src="paperclip" class="color-stroke-primary" />
			</button>
			<TiptapLinkModal v-if="shouldShowLink && editor" :editor="editor" :attributes="linkModalAttributes" ref="link-modal" />
			<TiptapVideoModal v-if="shouldShowVideoPicker && editor" :editor="editor" ref="video-modal" @uploadVideo="$emit('pick-file')" />
			<slot name="toolbar-extra-tools"></slot>
		</div>
	</transition>
	<div class="bg-stan-input-bg" :class="editorWrapperClass" key="content">
		<slot name="pre-editor"></slot>

		<EditorContent :editor="editor" class="editor" ref="editor" />
	</div>
</template>

<script>
	import StarterKit from '@tiptap/starter-kit'
	import { Editor, EditorContent } from '@tiptap/vue-3'
	import Link from '@tiptap/extension-link'
	import Placeholder from '@tiptap/extension-placeholder'
	import Mention from '@tiptap/extension-mention'
	import suggestion from './suggestion'
	import TiptapLinkModal from './TiptapLinkModal.vue'
	import { useCommunityStore } from '@/stores/communities'
	import TiptapVideoModal from './TiptapVideoModal.vue'
	import VideoEmbed from './embedVideo.js'

	export default {
		components: {
			EditorContent,
		},

		props: {
			value: {
				type: String,
				default: '',
			},
			placeholder: {
				type: String,
				default: '',
			},
			shouldShowFilePicker: {
				type: Boolean,
				default: false,
			},
			shouldShowImagePicker: {
				type: Boolean,
				default: false,
			},
			shouldShowVideoPicker: {
				type: Boolean,
				default: true,
			},
			showVideoEmbed: { type: Boolean, default: true },
			shouldShowLink: {
				type: Boolean,
				default: true,
			},
			hasToolbar: {
				type: Boolean,
				default: false,
			},
			editorWrapperClass: {
				type: String,
				default: '',
			},
			toolbarClass: {
				type: String,
				default: '',
			},
		},

		emits: ['update:value', 'pick-file'],

		data() {
			return {
				editor: null,
				activeToolbarBtns: [],
			}
		},

		watch: {
			value(newVal) {
				if (newVal === '') this.editor.commands.setContent(newVal)
			},
		},
		computed: {
			communityStore() {
				return useCommunityStore()
			},
			currentUser() {
				return this.communityStore.self?.community_member_id
			},
			linkModalAttributes() {
				if (!this.editor) return {}

				const { view, state } = this.editor
				const { from, to } = view.state.selection
				const text = state.doc.textBetween(from, to, '')

				return { text, ...this.editor.getAttributes('link') }
			},
		},
		mounted() {
			this.editor = new Editor({
				extensions: [
					StarterKit,
					Link.configure({
						openOnClick: false,
						autolink: false,
						defaultProtocol: 'https',
						protocols: ['https', 'mailto'],
					}),
					Placeholder.configure({
						placeholder: this.placeholder,
					}),
					Mention.configure({
						HTMLAttributes: {
							class: 'mention',
						},
						suggestion: suggestion,
						renderHTML: ({ options, node }) => {
							const memberId = node.attrs.id
							const isCurrentUser = memberId === this.currentUser
							return [
								'span',
								{
									'data-type': 'mention',
									'data-id': memberId,
									'data-user': memberId,
									'data-label': node.attrs.label,
									class: isCurrentUser ? 'current-user-mention' : 'mention',
								},
								`@${node.attrs.label}`,
							]
						},
					}),
					VideoEmbed,
				],
				content: this.value,
				onUpdate: () => {
					// Retain whitespace
					let editorHtml = this.editor.getHTML()
					const newHtml = editorHtml.replace(/<p><\/p>/g, '<p><br></p>')
					this.$emit('update:value', newHtml)
				},
			})
		},

		beforeUnmount() {
			this.editor.destroy()
		},
		methods: {
			toggleBold() {
				this.editor.chain().focus().toggleBold().run()
			},
			toggleItalic() {
				this.editor.chain().focus().toggleItalic().run()
			},
			toggleBulletList() {
				this.editor.chain().focus().toggleBulletList().run()
			},
			toggleLink() {
				this.editor.chain().focus().toggleLink().run()
			},
			setLink() {
				this.$refs['link-modal'].onLoad()
			},
			setVideo() {
				this.$refs['video-modal'].onLoad()
			},
			toggleToolbarBtn(btn) {
				const exists = this.activeToolbarBtns.includes(btn)
				if (exists) {
					this.activeToolbarBtns = this.activeToolbarBtns.filter(b => b !== btn)
				} else {
					this.activeToolbarBtns.push(btn)
				}
			},
			focus() {
				this.editor.chain().focus()
			},
		},
	}
</script>

<style lang="scss" scoped>
	.editor {
		@include para-1;
		@apply p-1 rounded-lg  max-w-[80%] md:max-w-full;
	}
	:deep(.tiptap) {
		p {
			&.is-editor-empty {
				&:first-child {
					&::before {
						color: var(--stan-gray-dark-color);
						content: attr(data-placeholder);
						float: left;
						height: 0;
						pointer-events: none;
					}
				}
			}
		}
	}
	#toolbar-container {
		@apply flex flex-row justify-start items-center bg-stan-bg h-0 overflow-hidden rounded-xl mb-1 gap-2 transition-all duration-500 ease-in-out -mt-2 px-2;

		button {
			@apply transition duration-500 ease-in-out p-1 rounded hover:bg-stan-gray-primary;

			&.ql-active {
				@apply bg-stan-gray-strike;
			}
		}

		.app-icon {
			:deep(path),
			:deep(rect) {
				fill: var(--stan-text-primary-color);
			}
		}

		border: none;
		border-bottom: 0px solid var(--stan-gray-primary-color);

		.ql-formats {
			display: inline-flex;
			gap: 6px;

			&:after {
				content: none;
			}
		}

		.ql-formats:not(:last-child) {
			margin: 0;
			padding-right: 0.25rem;
			border-right: 1px solid var(--stan-gray-strike-color);
		}

		.ql-formats:not(:first-child) {
			padding-left: 0.25rem;
		}
	}
</style>
