<template>
	<div class="app-card-container">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'AppCard',
		props: {},
		computed: {},
		created() {},
		data() {
			return {
				toggled: false,
			}
		},
		methods: {},
		watch: {},
	}
</script>
<style lang="scss" scoped>
	.app-card-container {
		box-shadow: var(--stan-box-shadow);
		@apply bg-stan-foreground rounded-xl overflow-hidden;
	}
</style>
